import { brickOptions } from 'components/BrickSelector/options';
import { sizeOptions } from 'components/SizeSelector/options';


export const createPreviewTableData = (brickValues, brickSize) => brickValues
  .filter(({ type }) => Boolean(type))
  .map(({ type: brickType, percent: brickPercent }, _, array) => {
    const { image, label, isPremium } = brickOptions.find(({ type }) => type === brickType);
    const size = sizeOptions.find(({ value }) => value === String(brickSize)).firstLabel;

    const percent = array.length === 1 ? 100 : brickPercent;

    const bricksCountForSquareMeter = {
      0.5: 51,
      0.7: 51,
      WDF: 59,
      hndWDF: 59,
    }[brickSize];

    return {
      image: image,
      name: label,
      size,
      percent,
      count: Math.round((percent / 100) * bricksCountForSquareMeter),

      isPremium,
    };
  });


const defineBlockHeight = (index, array) => {
  if (array.length - 1 === index) {
    return 100 - array[array.length - 2];
  } else {
    if (index === 0) {
      return array[index];
    }

    return array[index] - array[index - 1];
  }
};

export const createMarksTableData = (brickValues, brickSize, brickRanges) => brickValues
  .filter(({ type }) => Boolean(type))
  .map(({ type: brickType }, indexOfBrick, array) => {
    const { label, isPremium } = brickOptions.find(({ type }) => type === brickType);

    const size = sizeOptions.find(({ value }) => value === String(brickSize)).firstLabel;

    const brickPercent = defineBlockHeight(indexOfBrick, [...brickRanges, null]);

    const percent = array.length === 1 ? 100 : brickPercent;

    const bricksCountForSquareMeter = {
      0.5: 51,
      0.7: 51,
      WDF: 59,
      hndWDF: 59,
    }[brickSize];

    return {
      indexOfBrick: indexOfBrick + 1,
      name: label,
      size,
      percent,
      count: Math.round((percent / 100) * bricksCountForSquareMeter),

      isPremium,
    };
  });