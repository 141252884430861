import React, { useContext, useMemo, useState, memo } from 'react';
import { MobileHeaderContainer, MobileHeaderInner } from './styled';
import { Logo, LogoBG, DownloadButton, DownloadLogoBlock, DownloadButtonText } from 'components/Navigation/styled';
import fjLogo from 'components/Navigation/fj-logo.svg';
import { DownloadLogo } from 'components/Navigation/logo/DownloadLogo';
import { InfoLogo } from 'components/Navigation/logo/InfoLogo';
import { ViewLogo } from 'components/Navigation/logo/ViewLogo';
import { AppContext } from 'App';
import { seamColorOptions } from 'components/SeamSelector/options';
import { createImage } from 'createImage';
import { createPDF } from 'createPDF';
import { createBrickLayout } from 'createBrickLayout';
import { defineBricksPercentage } from 'brickSizes';
import { DownloadModal } from 'components/Navigation/DownloadModal/DownloadModal';
import Loader from 'components/Navigation/Loader';

export const MobileHeader = memo(({ isMobileTutorial, isViewerVisible }) => {
  const {
    handleChangeIsMobileTutorial,
    handleChangeIsViewerVisible,
    setCurrentPage,
    brickValues,
    brickRanges,
    currentSeamType: seamSize,
    currentSeamColor: seamColor,
    currentBond: bondType,
    currentSize: brickSize,
  } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [loaderText, setLoaderText] = useState('');
  const isTutorial = useMemo(() => brickValues.every(({ type }) => !type), [brickValues]);

  const seamImage = seamColorOptions.find(({ colorCode }) => colorCode === seamColor).image;

  const valuesForBricks = defineBricksPercentage(brickValues, brickRanges, brickSize);

  const handleChangeTutorial = () => {
    setCurrentPage(null);

    handleChangeIsMobileTutorial(true);
    handleChangeIsViewerVisible(false);
  };

  const handleChangeIsViewer = () => {
    setCurrentPage(null);

    handleChangeIsMobileTutorial(false);
    handleChangeIsViewerVisible(true);
  };

  const handleDownloadPDF = () => {
    setIsModalOpen(false);

    if (!isTutorial) {
      (async () => {
        setLoaderText('Генерация PDF');
        setIsLoaderVisible(true);

        await createPDF(valuesForBricks, brickSize, seamSize, seamImage, bondType);

        setIsLoaderVisible(false);
      })();
    }
  };

  const handleDownloadImage = () => {
    setIsModalOpen(false);

    if (!isTutorial) {
      createImage(async (canvas) => {
        setLoaderText('Генерация изображения');
        setIsLoaderVisible(true);

        await createBrickLayout(canvas, valuesForBricks, brickSize, seamSize, seamImage, bondType);

        setIsLoaderVisible(false);
      });
    }
  };

  const handleDownload = () => {
    if (!isTutorial) {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <MobileHeaderContainer>
      <DownloadModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        handleDownloadPDF={handleDownloadPDF}
        handleDownloadImage={handleDownloadImage}
      />

      <Loader isVisible={isLoaderVisible} loaderText={loaderText} />

      <MobileHeaderInner>
        <LogoBG>
          <Logo src={fjLogo} />
        </LogoBG>

        <DownloadButton style={{ marginLeft: 17 }} isActive={isMobileTutorial} onClick={handleChangeTutorial}>
          <DownloadLogoBlock>
            <InfoLogo />
          </DownloadLogoBlock>

          <DownloadButtonText>Справка</DownloadButtonText>
        </DownloadButton>

        <DownloadButton style={{ marginLeft: 7 }} isActive={isViewerVisible} onClick={handleChangeIsViewer}>
          <DownloadLogoBlock>
            <ViewLogo />
          </DownloadLogoBlock>

          <DownloadButtonText>Просмотр</DownloadButtonText>
        </DownloadButton>

        <DownloadButton style={{ marginLeft: 11 }} onClick={handleDownload}>
          <DownloadLogoBlock>
            <DownloadLogo />
          </DownloadLogoBlock>

          <DownloadButtonText>Скачать</DownloadButtonText>
        </DownloadButton>
      </MobileHeaderInner>
    </MobileHeaderContainer>
  );
});
