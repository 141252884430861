import React from 'react';

export const QuarterBond = () => (
  <svg width="60" height="26" viewBox="0 0 60 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 9H20V17H3V9Z" fill="black" className='main' />
    <path d="M0 18H17V26H0V18Z" fill="black" className='main' />
    <path d="M39 9H56V17H39V9Z" fill="black" className='main' />
    <path d="M36 18H53V26H36V18Z" fill="black" className='main' />
    <path d="M42 8L25 8V0L42 1.48619e-06V8Z" fill="black" className='marked' />
    <path d="M60 8L43 8V0L60 1.48619e-06V8Z" fill="black" className='main' />
    <path d="M24 8L7 8L7 0L24 1.48619e-06V8Z" fill="black" className='main' />
    <path d="M38 17L21 17V9L38 9V17Z" fill="black" className='marked' />
    <path d="M35 26L18 26V18L35 18V26Z" fill="black" className='marked' />
  </svg>
);
