export const createImage = async (createLayout) => {
  const canvas = document.createElement('canvas');

  canvas.height = 4000;
  canvas.width = 4000;

  const root = document.getElementsByTagName('html')[0];

  root.classList.add('hidden-overflow');
  document.body.classList.add('hidden-overflow');

  document.body.appendChild(canvas);

  await createLayout(canvas);

  const blob = await (new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    })
  }));

  const link = document.createElement('a');
  document.body.appendChild(link);

  link.style.display = 'none';
  link.download = 'kerma.jpg';

  link.href = window.URL.createObjectURL(blob);
  link.target = '_blank';

  link.click();

  document.body.removeChild(canvas);
  document.body.removeChild(link);

  document.body.classList.remove('hidden-overflow');
  root.classList.remove('hidden-overflow');
};
