import React from 'react';
import { SizeIcon07nf } from './icons/SizeIcon07nf';
import { SizeIcon1nf } from './icons/SizeIcon1nf';
import { SizeIconWDF } from './icons/SizeIconWDF';

export const sizeOptions = [
  {
    firstLabel: '0.5 NF',
    secondLabel: '250x60x65',
    value: '0.5',
    icon: <SizeIcon07nf />,
  },
  {
    firstLabel: '0.7 NF',
    secondLabel: '250x85x65',
    value: '0.7',
    icon: <SizeIcon1nf />,
  },
  {
    firstLabel: 'WDF',
    secondLabel: '215x102x65',
    value: 'WDF',
    icon: <SizeIconWDF />,
  },
  {
    firstLabel: 'Кирпич ручной формовки WDF',
    secondLabel: '215x102x65',
    value: 'hndWDF',
    icon: <SizeIconWDF />,
  },
];
