import styled, { css } from 'styled-components';

const size = {
  mobile: '325px',
  tablet: '768px',
  laptop: '1024px',
  desktop: '1440px',
  desktopL: '1800px',
};

const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
};

export const ViewerContainer = styled.section`
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  background-color: #2a2b2c;

  @media screen and (max-width: 1000px) {
    ${({ isMobileTutorial }) => isMobileTutorial && css`
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    `}

    background: #2a2b2c;
  }
`;

export const TutorialContainer = styled.div`
  background: #141415;
  opacity: 0.9;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media screen and (max-width: 1000px) {
    height: calc(100% + 1px);
    background: none;
  }
`;

export const TutorialHeading = styled.h2`
  flex-grow: 0;
  flex-shrink: 0;

  font-family: Playfair Display;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 2rem;

  text-align: left;
  text-transform: uppercase;

  color: #7CC5AF;
  margin: 5.875rem 0 2.4375rem 6.125rem;

  @media screen and (max-width: 1000px) {
    margin: 2.75rem auto 2.125rem auto;
    text-align: center;
  } 
`;

export const TutorialDescription = styled.p`
  flex-grow: 0;
  flex-shrink: 0;

  margin-left: 6.125rem;
  margin-bottom: 5.5rem;

  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;

  color: #FFFFFF;

  @media screen and (max-width: 1000px) {
    width: 85.75%;
    margin: auto;
    text-align: center;
    margin-bottom: 4.25rem;
  }
`;

export const TutorialActionsList = styled.ul`
  flex-grow: 0;
  flex-shrink: 0;

  margin-left: 6.125rem;
  padding: 0;

  @media screen and (max-width: 1000px) {
    margin-left: auto;
    margin-right: auto;
    width: 18.875rem;
    margin-bottom: 5.125rem;
  }
`;

export const TutorialAction = styled.li`
  list-style-type: none;

  display: flex;
  align-items: center;
  height: 1.5rem;
  
  margin-bottom: .75rem;

  @media screen and (max-width: 1000px) {
    &:nth-of-type(2) {
      margin-bottom: 1.5rem;
    } 

    &:nth-of-type(3) {
      margin-bottom: 1.5rem;
    }
  }
`;

export const TutorialActionIconContainer = styled.div`
  margin-right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  background: #C8AF64;
  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TutorialActionIcon = styled.img`
`;

export const TutorialActionText = styled.p`
  margin: 0;
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;

  color: #FFFFFF;
`;

export const TutorialClarificationBlock = styled.div`
  flex-grow: 1;

  display: flex;

  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: 1000px) {
    margin-bottom: 3.75rem;
  }
`;

export const TutorialClarification = styled.p`
  margin: 0;
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;

  margin-bottom: 4.5625rem;
  margin-left: 6.125rem;

  color: #FFFFFF;

  @media screen and (max-width: 1000px) {
    margin: 0 auto 0 auto;
    width: 84.25%;
    text-align: center;
  }
`;

export const ViewerCanvas = styled.canvas`
  position: absolute;
`;

export const CanvasImg11 = styled.img`
  position: absolute;
  border-right: 2px solid #444;
  width: 100%;
  height: 100%;
  transform: scale(0.5);
  transform-origin: 55% 50%;
  clip-path: polygon(27.1% 0%,100% 12%,100% 100%,27.5% 100%);

  @media ${device.mobile} {
    clip-path: polygon(28.1% 30%,100% 42%,100% 100%,27.5% 100%);
  }

  @media ${device.tablet} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(12.1% 0%,100% 13%,100% 81%,10% 82%);
  }

  @media ${device.laptop} { 
    width: 100%;
    right: -1px;
    clip-path: polygon(14.1% 10%,100% 23%,100% 94%,15.5% 96%);
  }
  
  @media ${device.desktop} {
    right: 0;
    width: 60%;
    position: absolute;
    left: 31%;
    clip-path: polygon(22.1% 13%,100% 24%,100% 100%,23.5% 100%);
  }

  @media ${device.desktopL} {
    width: 170%;
    left: -759px;
    height: 110%;
    position: absolute;
    clip-path: polygon(56% 20%,100% 32%,100% 90%,56% 90%);
  }
`;

export const CanvasImg12 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
  transform-origin: 77.5% 50%;
  clip-path: polygon(100% 31%,80% 0%,59.3% 25%,60.7% 100%,100% 100%);

  @media ${device.mobile} {
    clip-path: polygon(100% 51%,80% 30%,59.3% 55%,60.7% 100%,100% 100%);
  }

  @media ${device.tablet} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 12%,62% -3%,40.3% 18%,39.7% 74%,100% 78%);
  }

  @media ${device.laptop} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 38%,63% 9%,22.3% 28%,27.7% 90%,100% 90%);
  }

  @media ${device.desktop} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 33%,84% 11%,62.3% 38%,62.8% 100%,100% 100%);
  }
  
  @media ${device.desktopL} {
    height: 110%;
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 31%,68% 14%,47.3% 37%,48.7% 90%,100% 90%);
  }
`;

export const Cube11 = styled.div`
  position: absolute;
  left: -4%; 
  width: 100%;
  height: 150%;
  transform-style: preserve-3d;
  transform: perspective(1220px) rotate3d(-11,146,7,-45deg);
  perspective-origin: right bottom;

  @media ${device.mobile} {
    left: -1.3%;
    top: -69px;
    width: 30%;
    height: 70%;
    transform: perspective(500px) rotate3d(-11,146,7,-45deg);
  }

  @media ${device.tablet} {
    left: -7.6%;
    width: 62%;
    top: 50px;
    height: 100%;
    transform: perspective(1111px) rotate3d(7,100,2,-45deg);
  }

  @media ${device.laptop} {
    top: 0;
    left: -9%;
    width: 62%;
    height: 100%;
    transform: perspective(671px) rotate3d(-11,106,5,-45deg);
  }

  @media ${device.desktop} {
    height: 130%;
    left: -20.8%;
    width: 100%;
    top: 3%;
    transform: perspective(1230px) rotate3d(-9,126,4,-42deg);
  }

  @media ${device.desktopL} {
    transform: perspective(1670px) rotate3d(-10,72,2,-37deg);
    left: -4.6%;
    top: -135px;
    width: 100%;
    height: 200%;
  }
`;

export const CanvasImg13 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-right: 2px solid #444;
  transform: scale(0.5);
  transform-origin: 55% 50%;
  clip-path: polygon(75.1% 20%,100% 18%,100% 100%,74.5% 100%);

  @media ${device.mobile} {
    border-right: 1px solid #444;
    clip-path: polygon(70.1% 24%,100% 20%,100% 75%,68.5% 77%);
  }

  @media ${device.tablet} { 
    width: 70%;
    border-right: 2px solid #444;
    right: 6.6%;
    clip-path: polygon(68.1% 12%,100% 10%,100% 71%,66% 72%);
  }

  @media ${device.laptop} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(55.1% 27%,100% 25%,100% 90%,53.5% 86%);
  }
  
  @media ${device.desktop} {
    right: 0;
    width: 100%;
    clip-path: polygon(61.8% 32%,100% 25%,100% 100%,62.5% 100%);
  }

  @media ${device.desktopL} {
    clip-path: polygon(81% 38%,100% 39%,100% 90%,80.9% 90%);
    right: -158px;
    width: 150%;
    height: 110%;
  }
`;

export const CanvasImg14 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
  transform-origin: 77.5% 50%;
  clip-path: polygon(110% 31%,80% 0%,88.3% 5%,92.7% 100%,102% 100%);

  @media ${device.mobile} {
    clip-path: polygon(100% 21%,88.3% 15%,91.7% 80%,102% 80%);
  }

  @media ${device.tablet} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 14%,84.3% 12%,84.7% 69%,100% 74%);
  }

  @media ${device.laptop} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 29%,42% 9%,53.3% 7%,76.7% 89%,100% 90%);
  }

  @media ${device.desktop} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 30%,63% 0%,87.3% 26%,86.7% 100%,100% 100%);
  }
  
  @media ${device.desktopL} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(99.7% 39%,91.3% 36%,91.7% 90%,99.8% 89%);
    height: 110%;
    left: -3px;
  }
`;

export const Cube12 = styled.div`
  position: absolute;
  transform-style: preserve-3d;
  perspective-origin: right bottom;
  transform: perspective(1220px) rotate3d(-11,199,7,-45deg);
  left: -39.5%;
  top: 0;
  width: 100%;
  height: 150%;

  @media ${device.mobile} {
    left: -11%;
    top: -26px;
    width: 30%;
    height: 70%;
    transform: perspective(500px) rotate3d(-4,146,1,-45deg);
  }

  @media ${device.tablet} {
    left: -26.6%;
    top: 50px;
    width: 62%;
    height: 100%;
    transform: perspective(1001px) rotate3d(7,100,2,-45deg);
  }

  @media ${device.laptop} {
    left: -15%;
    top: 0;
    width: 46%;
    height: 100%;
    transform: perspective(671px) rotate3d(-11,106,5,-45deg);
  }

  @media ${device.desktop} {
    height: 130%;
    top: 0;
    left: -16%;
    width: 60%;
    transform: perspective(990px) rotate3d(-16,116,4,-45deg);
  }

  @media ${device.desktopL} {
    transform: perspective(1810px) rotate3d(-23,199,7,-45deg);
    left: -37.5%;
    top: -159px;
    width: 100%;
    height: 200%;
  }
`;

export const CanvasImg15 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5);
  transform-origin: 55% 50%;
  clip-path: polygon(70.1% 63%,100% 63%,99% 76%,70.5% 100%);
  border-right: 1px solid black;

  @media ${device.mobile} {
    clip-path: polygon(89.1% 86%,100% 85%,100% 100%,90% 100%);
  }

  @media ${device.tablet} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(89.1% 16%,100% 16%,100% 81%,89% 63%);
  }

  @media ${device.laptop} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(77.1% 43%,100% 44%,100% 89%,73.5% 86%);
  }
  
  @media ${device.desktop} {
    clip-path: polygon(83.1% 66%,100% 66%,100% 86%,83.5% 100%);
    width: 100%;
    right: 0;
  }

  @media ${device.desktopL} {
    clip-path: polygon(89.7% 63%,100% 63%,100% 76%,89.5% 100%);
    width: 200%;
    right: -22.6%;
  }
`;

export const CanvasImg16 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
  transform-origin: 77.5% 50%;
  clip-path: polygon(100% 63%,100% 62%,96.3% 62%,95.7% 100%,101% 100%);

  @media ${device.mobile} {
    clip-path: polygon(100% 73%,100% 84%,97.3% 85%,96.7% 100%,101% 100%);
  }

  @media ${device.tablet} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 16.6%,94.3% 16%,91.7% 36%,100% 28%);
  }

  @media ${device.laptop} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 44%,93.3% 43.9%,96.7% 90%,100% 50%);
  }

  @media ${device.desktop} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 63%,100% 66%,96.3% 65%,95.7% 100%,101% 100%);
  }
  
  @media ${device.desktopL} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 63%,100% 62%,96.3% 62%,95.7% 100%,101% 100%);
  }
`;

export const Cube13 = styled.div`
  position: absolute;
  transform-style: preserve-3d;
  perspective-origin: right bottom;
  transform: perspective(860px) rotate3d(-5,12,1,-45deg);
  left: 12.9%;
  top: -340px;
  width: 60%;
  height: 150%;

  @media ${device.mobile} {
    left: -4.6%;
    top: -200px;
    width: 30%;
    height: 70%;
    transform: perspective(400px) rotate3d(-39,146,13,-52deg);
  }

  @media ${device.tablet} {
    left: -17.2%;
    top: -38px;
    width: 62%;
    height: 100%;
    transform: perspective(551px) rotate3d(7,100,2,-45deg);
  }

  @media ${device.laptop} {
    left: -2%;
    top: -128px;
    width: 46%;
    height: 100%;
    transform: perspective(1180px) rotate3d(-6,48,6,-45deg);
  }

  @media ${device.desktop} {
    transform: perspective(1130px) rotate3d(-5,12,1,-45deg);
    left: 1.1%;
    width: 60%;
    top: -131px;
  }

  @media ${device.desktopL} {
    transform: perspective(860px) rotate3d(-5,12,1,-45deg);
    left: 12.9%;
    top: -241px;
    width: 60%;
    height: 150%;
  }
`;

export const CanvasImg17 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5);
  transform-origin: 55% 50%;
  clip-path: polygon(94.1% 61%,100% 62%,100% 79%,93.5% 101%);
  border-right: 1px solid black;

  @media ${device.mobile} {
    width: 0;
  }

  @media ${device.tablet} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(96.1% 40%,100% 40%,100% 48%,96% 50%);
  }

  @media ${device.laptop} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(92.1% 43%,100% 44%,100% 56%,91.5% 54%);
  }
  
  @media ${device.desktop} {
    clip-path: polygon(92.1% 65%,100% 66%,100% 84%,92.5% 80%);
  }

  @media ${device.desktopL} {
    clip-path: polygon(94.1% 60.8%,100% 62%,100% 79%,94.1% 77%);
    top: -96px;
    height: 130%;
  }
`;

export const CanvasImg18 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
  transform-origin: 77.5% 50%;
  clip-path: polygon(100% 61%,100% 62%,68.3% 61%,69.7% 100%,100% 100%);

  @media ${device.mobile} {
    clip-path: polygon(100% 70%,87% 70%,87.7% 76%,100% 80%);
  }

  @media ${device.tablet} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 41%,83.3% 41%,83.7% 56%,100% 51%);
  }

  @media ${device.laptop} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 44%,61.3% 41.9%,59.7% 58%,100% 53%);
  }

  @media ${device.desktop} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 66%,81% 66%,80.9% 82%,100% 81%);
  }
  
  @media ${device.desktopL} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 61%,100% 62%,68.3% 61%,68.7% 83%,100% 77%);
    top: -96px;
    height: 130%;
  }
`;

export const Cube14 = styled.div`
  position: absolute;
  transform-style: preserve-3d;
  perspective-origin: right bottom;
  transform: perspective(734px) rotate3d(-4,140,1,-49deg);
  left: -37.1%;
  top: 228px;
  width: 65%;
  height: 100%;

  @media ${device.mobile} {
    left: -19%;
    top: -40px;
    width: 30%;
    height: 70%;
    transform: perspective(500px) rotate3d(-11,146,7,-45deg);
  }

  @media ${device.tablet} {
    left: -39.3%;
    top: 100px;
    width: 62%;
    height: 100%;
    transform: perspective(1001px) rotate3d(7,100,2,-45deg);
  }

  @media ${device.laptop} {
    left: -26.6%;
    top: 85px;
    width: 46%;
    height: 100%;
    transform: perspective(1380px) rotate3d(0,130,-2,-45deg);
  }

  @media ${device.desktop} {
    transform: perspective(980px) rotate3d(0,15,0,-53deg);
    left: -33.2%;
    width: 60%;
    top: 173px;
  }

  @media ${device.desktopL} {
    transform: perspective(734px) rotate3d(-4,140,1,-49deg);
    left: -37.1%;
    top: 329px;
    width: 65%;
    height: 100%;
  }
`;

export const CubeWrapper = styled.div`
  position: absolute;
  width: 1400px;
  height: 560px;

  & > div > img {
    image-rendering: -webkit-optimize-contrast;
  }
`

export const CanvasImg21 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5);
  transform-origin: 55% 50%;
  clip-path: polygon(27.1% 0%,100% 12%,100% 100%,27.5% 100%);

  @media ${device.mobile} {
    width: 70%;
    right: 6.6%;
    clip-path: polygon(31.1% 30%,58% 8%,100% 12%,100% 98.3%,30.5% 94%);
  }

  @media ${device.tablet} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(52.8% 51%,76% 6%, 101% 40%, 100% 99.8%,53.5% 99.4%);
  }

  @media ${device.laptop} { 
    width: 100%;
    right: 0%;
    clip-path: polygon(67.1% 63%,80% 20%,100% 28%,100% 98%,67.5% 97%);
  }
  
  @media ${device.desktop} {
    clip-path: polygon(23.5% 49%,51% 0%,100% 0%,100% 100%,24% 100%);
    right: 76px;
    top: 74px;
    width: 76%;
    height: 88%;
  }

  @media ${device.desktopL} {
    clip-path: polygon(23.5% 49%,51% 0%,100% 0%,100% 100%,24% 100%);
    right: 76px;
    top: 74px;
    width: 76%;
    height: 88%;
  }
`;

export const CanvasImg22 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
  transform-origin: 77.5% 50%;
  clip-path: polygon(100% 31%,80% 0%,59.3% 25%,60.7% 100%,100% 100%);

  @media ${device.mobile} {
    width: 40%;
    right: 13.6%;
    clip-path: polygon(100% 31%,69.3% 35%,70.7% 98%,100% 98%);
  }

  @media ${device.tablet} {
    width: 70%;
    right: 6.6%;
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 31%,69% 60%,68.7% 99.2%,100% 99.8%);
  }

  @media ${device.laptop} {
    width: 100%;
    right: 0%;
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 51%,75.3% 48%,76.5% 98%,100% 99%);
  }

  @media ${device.desktop} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 31%,68.5% 40%,69.6% 100%,100% 100%);
    height: 88%;
    top: 74px;
    width: 55%;
    left: 488px;
  }
  
  @media ${device.desktopL} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 31%,68.5% 40%,69.6% 100%,100% 100%);
    height: 88%;
    top: 74px;
    width: 55%;
    left: 488px;
  }
`;

export const Cube21 = styled.div`
  position: absolute;
  left: -26.4%;
  top: 50px;
  width: 100%;
  height: 150%;
  transform: perspective(1540px) rotate3d(-11,136,7,-62deg);
  transform-style: preserve-3d;
  perspective-origin: right bottom;

  @media ${device.mobile} {
    left: -22.2%;
    top: 69px;
    width: 50%;
    height: 70%;
    transform: perspective(740px) rotate3d(-11,136,7,-64deg);
  }

  @media ${device.tablet} {
    left: -45.9%;
    top: -51px;
    width: 100%;
    height: 120%;
    transform: perspective(1340px) rotate3d(-11,136,4,-62deg);
  }

  @media ${device.laptop} {
    left: -50.2%;
    top: -174px;
    width: 100%;
    height: 150%;
    transform: perspective(1530px) rotate3d(-11,136,6,-59deg);
  }

  @media ${device.desktop} {
    left: -40%;
    top: -115px;
    width: 100%;
    height: 175%;
    transform: perspective(1730px) rotate3d(-11,136,6,-62deg);
  }

  @media ${device.desktopL} {
    left: -25.7%;
    top: -72px;
    width: 100%;
    height: 175%;
    transform: perspective(1730px) rotate3d(-11,136,6,-62deg);
  }
`;

export const CanvasImg23 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5);
  transform-origin: 55% 50%;
  clip-path: polygon(27.1% 0%,100% 12%,100% 100%,27.5% 100%);

  @media ${device.mobile} {
    clip-path: polygon(88.1% 30%,100% 62%,100% 100%,87.5% 100%);
  }

  @media ${device.tablet} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(94.1% 30%,100% 32%,100% 100%,94.5% 100%);
  }

  @media ${device.laptop} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(97.4% 20%,100% 9%,100% 63.8%,95.8% 63.8%);
  }
  
  @media ${device.desktop} {
    clip-path: polygon(94.1% 30%,100% 36%,100% 100%,93.4% 100%);
    height: 88%;
    top: 74px;
    width: 80%;
    left: 217px;
  }

  @media ${device.desktopL} {
    clip-path: polygon(94.1% 30%,100% 36%,100% 100%,93.4% 100%);
    height: 88%;
    top: 74px;
    width: 80%;
    left: 217px;
  }
`;

export const CanvasImg24 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
  transform-origin: 77.5% 50%;
  clip-path: polygon(100% 31%,80% 0%,59.3% 25%,60.7% 100%,100% 100%);

  @media ${device.mobile} {
    width: 40%;
    right: 13.6%;
    clip-path: polygon(100% 31%,80% 20%,65% 64%,65.7% 97%,100% 100%);
  }

  @media ${device.tablet} {
    width: 100%;
    right: 0;
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 41%,72% 20%, 32% 45%,32.7% 100%,100% 100%);
  }

  @media ${device.laptop} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 12%,86% 0%,63.1% 36%,63.7% 62.6%,100% 62.8%);
  }

  @media ${device.desktop} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 34%,80.5% 9%,48.5% 56%,49.9% 100%,100% 100%);
    height: 88%;
    top: 74px;
    width: 50%;
    left: 542px;
  }
  
  @media ${device.desktopL} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 34%,80.5% 9%,48.5% 56%,49.9% 100%,100% 100%);
    height: 88%;
    top: 74px;
    width: 50%;
    left: 542px;
  }
`;

export const Cube22 = styled.div`
  position: absolute;
  left: -4%; 
  width: 100%;
  height: 150%;
  transform-style: preserve-3d;
  transform: perspective(1220px) rotate3d(-11,146,7,-45deg);
  perspective-origin: right bottom;

  @media ${device.mobile} {
    left: -14.1%;
    top: 64px;
    width: 50%;
    height: 70%;
    transform: perspective(740px) rotate3d(-11,136,7,-64deg);
  }

  @media ${device.tablet} {
    left: -24.1%;
    width: 80%;
    top: -88px;
    height: 130%;
    transform: perspective(1720px) rotate3d(-11,146,4,-45deg);
  }

  @media ${device.laptop} {
    left: -39.6%;
    top: -28px;
    width: 100%;
    height: 150%;
    transform: perspective(1190px) rotate3d(2,696,5,-60deg);
  }

  @media ${device.desktop} {
    left: -23%;
    top: -68.1px;
    width: 100%;
    height: 162.9%;
    transform: perspective(1540px) rotate3d(-11,136,7,-62deg);
  }

  @media ${device.desktopL} {
    left: -8.7%;
    top: -25.1px;
    width: 100%;
    height: 160.9%;
    transform: perspective(1540px) rotate3d(-11,136,7,-62deg);
  }
`;

export const CanvasImg25 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5);
  transform-origin: 55% 50%;
  clip-path: polygon(27.1% 0%,100% 12%,100% 100%,27.5% 100%);

  @media ${device.mobile} {
    clip-path: polygon(98.1% 33%,100% 42%,100% 100%,96.5% 100%);
  }

  @media ${device.tablet} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(97% 13%,100% 0%,100% 78%,95% 78%);
  }

  @media ${device.laptop} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(98.1% 16%,100% 23%,104% 81%,96.5% 81%);
  }
  
  @media ${device.desktop} {
    clip-path: polygon(94.3% 27%,100% 13%,100% 99%,95% 99%);
    height: 88%;
    top: 74px;
    width: 70%;
    left: 327px;
  }

  @media ${device.desktopL} {
    clip-path: polygon(94.3% 27%,100% 13%,100% 99%,95% 99%);
    height: 88%;
    top: 74px;
    width: 70%;
    left: 327px;
  }
`;

export const CanvasImg26 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
  transform-origin: 77.5% 50%;
  clip-path: polygon(100% 31%,80% 0%,59.3% 25%,60.7% 100%,100% 100%);

  @media ${device.mobile} {
    width: 40%;
    right: 13.6%;
    clip-path: polygon(100% 42%,97% 39%,96.7% 100%,100% 100%);
  }

  @media ${device.tablet} {
    width: 100%;
    right: 0;
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 12%,95% 7%,96.4% 78%,100% 78%);
  }

  @media ${device.laptop} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 30%,95.3% 24%,96.1% 81%,100% 81%);
  }

  @media ${device.desktop} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 13%,97.1% 15%,97% 100%,100% 98%);
    height: 88%;
    top: 74px;
    width: 50%;
    left: 542px;
  }
  
  @media ${device.desktopL} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 13%,97.1% 15%,97% 100%,100% 98%);
    height: 88%;
    top: 74px;
    width: 50%;
    left: 542px;
  }
`;

export const Cube23 = styled.div`
  position: absolute;
  left: -4%; 
  width: 100%;
  height: 150%;
  transform-style: preserve-3d;
  transform: perspective(1220px) rotate3d(-11,146,7,-45deg);
  perspective-origin: right bottom;

  @media ${device.mobile} {
    left: -14.4%;
    top: 54px;
    width: 50%;
    height: 70%;
    transform: perspective(740px) rotate3d(-11,136,7,-64deg);
  }

  @media ${device.tablet} {
    left: -21.3%;
    width: 80%;
    top: -10px;
    height: 130%;
    transform: perspective(1720px) rotate3d(-11,146,4,-45deg);
  }

  @media ${device.laptop} {
    left: -40%;
    top: -100px;
    width: 100%;
    height: 150%;
    transform: perspective(2220px) rotate3d(-5,138,3,-43deg);
  }

  @media ${device.desktop} {
    left: -18.4%;
    top: -75.1px;
    width: 100%;
    height: 162.9%;
    transform: perspective(1540px) rotate3d(-11,135,4,-62deg);
  }

  @media ${device.desktopL} {
    left: -4.3%;
    top: -25.1px;
    width: 100%;
    height: 162.9%;
    transform: perspective(1540px) rotate3d(-11,135,4,-62deg);
  }
`;

export const CanvasImg27 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5);
  transform-origin: 55% 50%;
  clip-path: polygon(27.1% 0%,100% 12%,100% 100%,27.5% 100%);

  @media ${device.mobile} {
    clip-path: polygon(98.1% 50%,100% 32%,100% 100%,98.7% 100%);
  }

  @media ${device.tablet} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(97.2% 13%,100% 10%,100% 78%,96% 78%);
  }

  @media ${device.laptop} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(97.3% 28%,100% 27%,100% 75.4%,96.7% 75.5%);
  }
  
  @media ${device.desktop} {
    clip-path: polygon(94.5% 22%,100% 24%,100% 98%,94.8% 98%);
    height: 88%;
    top: 74px;
    width: 70%;
    left: 327px;
  }

  @media ${device.desktopL} {
    clip-path: polygon(94.5% 22%,100% 24%,100% 98%,94.8% 98%);
    height: 88%;
    top: 74px;
    width: 70%;
    left: 327px;
  }
`;

export const CanvasImg28 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
  transform-origin: 77.5% 50%;
  clip-path: polygon(100% 31%,80% 0%,59.3% 25%,60.7% 100%,100% 100%);

  @media ${device.mobile} {
    width: 40%;
    right: 13.6%;
    clip-path: polygon(100% 41%,95% 44%,96.4% 100%,100% 100%);
  }

  @media ${device.tablet} {
    width: 100%;
    right: 0;
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 12%,95.3% 13%,96.7% 78%,100% 78%);
  }

  @media ${device.laptop} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 28%,97% 26%,97.4% 76%,100% 75.4%);
  }

  @media ${device.desktop} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 16%,97.1% 25%,97.2% 98%,100% 100%);
    height: 88%;
    top: 74px;
    width: 50%;
    left: 542px;
  }
  
  @media ${device.desktopL} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 16%,97.1% 25%,97.2% 98%,100% 100%);
    height: 88%;
    top: 74px;
    width: 50%;
    left: 542px;
  }
`;

export const Cube24 = styled.div`
  position: absolute;
  left: -4%; 
  width: 100%;
  height: 150%;
  transform-style: preserve-3d;
  transform: perspective(1220px) rotate3d(-11,146,7,-45deg);
  perspective-origin: right bottom;

  @media ${device.mobile} {
    left: -10.3%;
    top: 48px;
    width: 50%;
    height: 70%;
    transform: perspective(740px) rotate3d(-11,136,4,-64deg);
  }

  @media ${device.tablet} {
    left: -13.8%;
    width: 80%;
    top: -13px;
    height: 130%;
    transform: perspective(1720px) rotate3d(-11,146,4,-45deg);
  }

  @media ${device.laptop} {
    left: -34.1%;
    top: -100px;
    width: 100%;
    height: 150%;
    transform: perspective(1220px) rotate3d(0,146,2,-46deg);
  }

  @media ${device.desktop} {
    left: -7.5%;
    top: -74.1px;
    width: 100%;
    height: 162.9%;
    transform: perspective(1540px) rotate3d(-11,136,4,-62deg);
  }

  @media ${device.desktopL} {
    left: 6.7%;
    top: -25.1px;
    width: 100%;
    height: 162.9%;
    transform: perspective(1540px) rotate3d(-11,136,4,-62deg);
  }
`;

export const CanvasImg29 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5);
  transform-origin: 55% 50%;
  clip-path: polygon(27.1% 0%,100% 12%,100% 100%,27.5% 100%);

  @media ${device.mobile} {
    clip-path: polygon(96.7% 27%,100% 28%,100% 50%,95.5% 60%);
  }

  @media ${device.tablet} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(95.1% 13.9%,100% 13%,100% 38%,95% 30%);
  }

  @media ${device.laptop} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(97.2% 30.7%,100% 30.4%,100% 44%,97.2% 36.1%);
  }
  
  @media ${device.desktop} {
    right: 0;
    width: 100%;
    clip-path: polygon(95.9% 37%,100% 37%,100% 75%,96.3% 66%);
  }

  @media ${device.desktopL} {
    clip-path: polygon(95.9% 37%,100% 37%,100% 75%,96.3% 66%);
  }
`;

export const CanvasImg210 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
  transform-origin: 77.5% 50%;
  clip-path: polygon(100% 31%,80% 0%,59.3% 25%,60.7% 100%,100% 100%);

  @media ${device.mobile} {
    width: 40%;
    right: 13.6%;
    clip-path: polygon(100% 28%,98% 28%,97.9% 50%,100% 50%);
  }

  @media ${device.tablet} {
    width: 100%;
    right: 0;
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 13.3%,96.3% 13%,96% 38%,100% 48%);
  }

  @media ${device.laptop} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 30.1%,96.4% 30%,96.7% 61%,100% 51%);
  }

  @media ${device.desktop} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 37%,98.4% 37%,98.5% 63%,100% 66%);
  }
  
  @media ${device.desktopL} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 37%,98.4% 37%,98.5% 63%,100% 66%);
  }
`;

export const Cube25 = styled.div`
  position: absolute;
  left: -4%; 
  width: 100%;
  height: 150%;
  transform-style: preserve-3d;
  transform: perspective(1220px) rotate3d(-11,146,7,-45deg);
  perspective-origin: right bottom;
    
  @media ${device.mobile} {
    left: -8.6%;
    top: 49px;
    width: 50%;
    height: 70%;
    transform: perspective(740px) rotate3d(-11,136,7,-64deg);
  }

  @media ${device.tablet} {
    left: -11.2%;
    width: 80%;
    top: -80px;
    height: 130%;
    transform: perspective(1720px) rotate3d(-11,146,4,-45deg);
  }

  @media ${device.laptop} {
    left: -31.8%;
    top: -190px;
    width: 100%;
    height: 150%;
    transform: perspective(1220px) rotate3d(0,146,2,-46deg);
  }

  @media ${device.desktop} {
    left: -7%;
    top: -219.1px;
    width: 100%;
    height: 162.9%;
    transform: perspective(2270px) rotate3d(-6,34,3,-52deg);
  }

  @media ${device.desktopL} {
    left: 7.3%;
    top: -166px;
    width: 100%;
    height: 162.9%;
    transform: perspective(2270px) rotate3d(-6,34,3,-52deg);
  }
`;

export const CanvasImg31 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5);
  transform-origin: 55% 50%;
  clip-path: polygon(19% 49%,102% 34%,100% 92%,19% 92%);
  border-right: 1px solid black;
  
  @media ${device.mobile} {
    clip-path: polygon(0% 52%,100% 40%,100% 79%,0% 78%);
    width: 70%;
    right: 6.6%;
  }

  @media ${device.tablet} { 
    clip-path: polygon(21% 50%,100% 31%,100% 82%,21% 82%);
    width: 70%;
    right: 6.6%;
  }

  @media ${device.laptop} {
    width: 74%;
    right: 5.8%;
    clip-path: polygon(0% 30%,100% 22%,100% 68.4%,0% 68%);
  }
  
  @media ${device.desktop} {
    right: 0;
    width: 100%;
    clip-path: polygon(20% 41%,99% 24%,102% 74%,21% 76%);
  }

  @media ${device.desktopL} {
    clip-path: polygon(2.2% 47%,100% 35%,100% 92%,2.7% 91%);
    right: -152px;
    width: 164%;
  }
`;

export const CanvasImg32 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
  transform-origin: 77.5% 50%;
  clip-path: polygon(100% 31%,73% 16%,31.3% 37%,31.7% 93%,100% 92%);
  
  @media ${device.mobile} {
    width: 70%;
    right: 6.6%;
    clip-path: polygon(100% 35%,31% 36%,27.7% 76%,100% 79.4%);
  }

  @media ${device.tablet} {
    width: 100%;
    right: 0;
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 31%,52% 46%,51.7% 82%,100% 82%);
  }

  @media ${device.laptop} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 30%,5.3% 27%,5.7% 68%,100% 68.4%);
  }

  @media ${device.desktop} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 29%,47.3% 36%,46.7% 76%,100% 75%);
    width: 70%;
    right: 97px;
  }
  
  @media ${device.desktopL} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 31%,15.3% 38%,14.7% 94%,100% 92%);
    width: 78%;
    right: 52px;
  }
`;

export const Cube31 = styled.div`
  position: absolute;
  left: -30%; 
  width: 100%;
  height: 150%;
  transform-style: preserve-3d;
  transform: perspective(3020px) rotate3d(-7,124,1,-45deg);
  perspective-origin: right bottom;
  
  @media ${device.mobile} {
    left: -13.2%;
    width: 37%;
    height: 67%;
    transform: perspective(1220px) rotate3d(-2,24,0,-44deg);
  }

  @media ${device.tablet} {
    left: -40.5%;
    top: -70px;
    width: 100%;
    height: 100%;
    transform: perspective(5270px) rotate3d(0,24,0,-55deg);
  }

  @media ${device.laptop} {
    left: -23%;
    width: 65%;
    height: 100%;
    top: 0;
    transform: perspective(1040px) rotate3d(-3,66,1,-51deg);
  }

  @media ${device.desktop} {
    left: -34.8%;
    width: 100%;
    top: -100px;
    height: 150%;
    transform: perspective(2120px) rotate3d(-2,59,0,-55deg);
  }

  @media ${device.desktopL} {
    left: -7.8%;
    width: 75%;
    height: 160%;
    top: -26px;
    transform: perspective(2120px) rotate3d(-3,50,1,-55deg);
  }
`;

export const CanvasImg33 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5);
  transform-origin: 55% 50%;
  clip-path: polygon(98.4% 19%,101% 18.8%,100% 42%,97.5% 40%);
  border-right: 1px solid black;
  
  @media ${device.mobile} {
    width: 70%;
    right: 6.6%;
    clip-path: polygon(97.1% 19%,101% 18.8%,100% 50%,97.5% 40%);
  }

  @media ${device.tablet} {
    width: 70%;
    right: 6.6%;
    clip-path: polygon(97.4% 17%,101% 16.8%,100% 41%,97.5% 43%);
  }

  @media ${device.laptop} { 
    width: 70%;
    right: 6.6%;
    clip-path: polygon(97% 19.4%,100% 19%,100% 43%,96% 39%);
  }
  
  @media ${device.desktop} {
    right: 0;
    width: 100%;
    clip-path: polygon(97.1% 22.2%,100% 21.8%,100% 42%,96.5% 40%);
  }

  @media ${device.desktopL} {
    clip-path: polygon(98.4% 14.5%,101% 13.8%,100% 42%,98.6% 40%);
  }
`;

export const CanvasImg34 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
  transform-origin: 77.5% 50%;
  clip-path: polygon(100% 19.2%,96.1% 19%,95.7% 42%,102% 43%);
  
  @media ${device.mobile} {
    width: 70%;
    right: 6.6%;
    clip-path: polygon(100% 19.2%,94.9% 18.9%,94.3% 42%,102% 43%);
  }

  @media ${device.tablet} {
    width: 100%;
    right: 0;
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 17.2%,94.3% 17%,94.3% 42%,100% 43%);
  }

  @media ${device.laptop} {
    transform: scale(0.5) scaleZ(-0.5) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 19.2%,94.1% 19%,93.7% 42%,102% 43%);
  }

  @media ${device.desktop} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 22.2%,96.2% 22.8%,96% 43.5%,100% 45%);
  }
  
  @media ${device.desktopL} {
    transform: scale(0.5) scaleZ(-1) rotateY(90deg) translateX(-22.4%);
    clip-path: polygon(100% 14.2%,95.3% 14%,94.7% 36%,100% 39%);
    width: 70%;
    left: 325px;
  }
`;

export const Cube32 = styled.div`
  position: absolute;
  left: -36.5%;
  width: 100%;
  height: 150%;
  transform-style: preserve-3d;
  transform: perspective(1550px) rotate3d(-4,64,1,-51deg);
  perspective-origin: right bottom;

  @media ${device.mobile} {
    left: -15.9%;
    top: 14px;
    width: 37%;
    height: 67%;
    transform: perspective(1220px) rotate3d(-2,24,0,-44deg);
  }

  @media ${device.tablet} {
    left: -26.5%;
    width: 70%;
    height: 70%;
    top: 0;
    transform: perspective(1550px) rotate3d(-4,64,1,-51deg);
  }

  @media ${device.laptop} {
    left: -28.4%;
    width: 67%;
    top: 0;
    height: 85%;
    transform: perspective(1140px) rotate3d(-3,40,0,-52deg);
  }

  @media ${device.desktop} {
    left: -17.7%;
    top: -86px;
    width: 66%;
    height: 118%;
    transform: perspective(1240px) rotate3d(37,742,-29,-56deg);
  }

  @media ${device.desktopL} {
    top: 0;
    left: -36%;
    width: 100%;
    height: 150%;
    transform: perspective(1240px) rotate3d(37,742,-29,-56deg);
  }
`;

// export const ViewerCanvas3d = styled.canvas`
//   transform: scale(0.55) translateZ(120px) translateX(70px) translateY(140px);
// `;

export const HouseImage = styled.div`
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${device.mobile} {
    width: 425px;
  }

  @media ${device.tablet} {
    width: 756px;
  }

  @media ${device.laptop} {
    width: 756px;
  }

  @media ${device.desktop} {
    width: 1100px;
  }

  @media ${device.desktopL} {
    width: 1400px;
  }

  & > img {
    width: 100%;
  }
`;

export const House2Image = styled.div`
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${device.mobile} {
    width: 425px;
  }

  @media ${device.tablet} {
    width: 756px;
  }

  @media ${device.laptop} {
    width: 756px;
  }

  @media ${device.desktop} {
    width: 1100px;
  }

  @media ${device.desktopL} {
    width: 1100px;
  }
  
  & > img {
    width: 100%;
  }
`;

export const House3Image = styled.div`
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -30px;

  @media ${device.mobile} {
    width: 425px;
  }

  @media ${device.tablet} {
    width: 756px;
  }

  @media ${device.laptop} {
    margin-top: -75px;
    width: 756px;
  }

  @media ${device.desktop} {
    width: 1100px;
  }
  
  @media ${device.desktopL} {
    width: 1400px;
  }
  
  & > img {
    width: 100%;
  }
`;

export const Viewer3dRoot = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${({ hidden }) => hidden && css`
    visibility: hidden;
  `}
`;

export const ArrowLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  color: #C8AF64;
  cursor: pointer;
  position: absolute;
  z-index: 3;
  left: 40px;
  bottom: 24px;
  box-shadow: 0px 5px 15px 2px rgba(0,0,0,0.1);

  &:after {
    display: block;
    content: '';
    width: 12px;
    height: 12px;
    border-top: 3px solid #C8AF64;
    border-left: 3px solid #C8AF64;
    border-radius: 3px;
    transform: rotate(-45deg);
    margin-left: 6px;
  }
  
  @media ${device.laptop} {
    top: 50%;
  }
`

export const ArrowRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  color: #C8AF64;
  cursor: pointer;
  position: absolute;
  z-index: 3;
  right: 40px;
  bottom: 24px;
  box-shadow: 0px 5px 15px 2px rgba(0,0,0,0.1);

  &:after {
    display: block;
    content: '';
    width: 12px;
    height: 12px;
    border-top: 3px solid #C8AF64;
    border-right: 3px solid #C8AF64;
    border-radius: 3px;
    transform: rotate(45deg);
    margin-right: 6px;
  }

  @media ${device.laptop} {
    top: 50%;
  }
`

export const ModeWrapper = styled.div`
  display: flex;
  width: 108px;
  height: 40px;
  color: #C8AF64;
  cursor: pointer;
  position: absolute;
  background-color: white;
  border-radius: 4px;
  font-size: 24px;
  z-index: 3;
  right: 80px;
  top: 5%;
  box-shadow: 0px 5px 15px 2px rgba(0,0,0,0.1);
`

export const ModeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 4px 12px;
  height: 40px;
  border-radius: 4px;
  color: #C8AF64;
  cursor: pointer;
  font-family: 'Playfair Display';

  ${({ active }) => active && css`
    color: white;
    background-color: #C8AF64;
  `}
`
export const CubesWrapper = styled.div`
  position: relative;
  z-index: 1;
  transform: translateZ(-1px);

  @media ${device.mobile} {
    width: 425px;
    min-width: 425px;
    height: 283px;
  }

  @media ${device.tablet} {
    width: 756px;
    min-width: 756px;
    height: 504px;
  }

  @media ${device.laptop} {
    width: 756px;
    height: 504px;
  }

  @media ${device.desktop} {
    width: 1100px;
    height: 733px;
  }
  
  @media ${device.desktopL} {
    width: 1400px;
    height: 933px;
  }
`

export const CubesWrapper2 = styled.div`
  position: relative;
  z-index: 1;
  transform: translateZ(-1px);

  @media ${device.mobile} {
    width: 425px;
    min-width: 425px;
    height: 283px;
    top: -100px;
  }

  @media ${device.tablet} {
    width: 756px;
    min-width: 756px;
    height: 504px;
    top: 0;
    left: -50px;
  }

  @media ${device.laptop} {
    width: 756px;
    height: 504px;
    left: 0;
  }

  @media ${device.desktop} {
    width: 1100px;
    height: 733px;
    top: 44px;
  }
  
  @media ${device.desktopL} {
    width: 1400px;
    height: 933px;
    top: 100px;
    left: -50px;
  }
`

export const CubesWrapper3 = styled.div`
  position: relative;
  z-index: 1;
  transform: translateZ(-1px);

  @media ${device.mobile} {
    width: 425px;
    min-width: 425px;
    height: 283px;
    top: -30px;
  }

  @media ${device.tablet} {
    width: 756px;
    min-width: 756px;
    height: 504px;
    top: 100px;
    left: -50px;
  }

  @media ${device.laptop} {
    width: 756px;
    height: 504px;
    left: 0;
    top: 25px;
  }

  @media ${device.desktop} {
    width: 1100px;
    height: 733px;
    top: 125px;
  }
  
  @media ${device.desktopL} {
    width: 1400px;
    height: 933px;
    top: 125px;
  }
`

export const House = styled.div`
  position: fixed;
  top: 30px;
  box-sizing: border-box;
  padding: 120px 0;
  width: calc(100% - 399px);
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  visibility: hidden;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  ${({ visible }) => visible && css`
    visibility: visible;
  `}
`;

export const Developer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 13px;
  color: white;

  & > a {
    color: currentColor;
  }
`;