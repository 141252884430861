import React, { useState, useMemo, Fragment, memo } from 'react';
import Tippy from '@tippyjs/react';
import { Range, Direction } from 'react-range';

import {
  ChooseItemContainer, SelectedSections, SelectorMainViewContainer, ScaleBlock,
  SelectedSection, ScaleMarkContainer, ScaleMark,
  ScaleText, DragThumb, DragThumbContainer,
  DragTrack, DragTrackInnerBlock, ChooseBrickBlock, ChooseBrickIconContainer,
  ChooseBrickText, PercentOfBrick, ChooseItemOptions, ChooseItemOption, ChooseItemOptionImage,
  ChooseItemOptionText, ChooseItemOptionButton, SelectedOptionImage, SelectedOptionContainer,
  SelectedOptionIconBlock, KermaPremiumImage, SelectedOptionLabel, AddButtonBlock,
  AddButtonBlockText, SelectorsMainView, TransparentBlock, SelectedSectionContent,
} from './styled';
import dragBrick from 'components/Viewer/icons/drag-brick.png'
import changeBrick from 'components/Viewer/icons/change-brick.png';
import removeBrick from 'components/Viewer/icons/remove-brick.png';
import kermaPremium from './kerma-premium.svg';
import { scales, brickOptions } from './options';
import { PlusIcon } from './PlusIcon';
import CustomScrollbar from 'components/CustomScrollbar';
import { useWindowSize } from 'hooks/useWindowSize';
import { blockWrongPercentage } from './blockWrongPercentage';
import { redefinePercentages } from 'brickSizes';
import equals from 'ramda/es/equals';

export const BrickSelector = memo(({ brickValues = [], brickRanges: values, onChangeRange, onChangeBrick, onCommitRange, onAddBrick }) => {
  const [isSelectorVisible, setIsSelectorVisible] = useState(false);
  const [currentSelectedSection, setCurrentSelectedSection] = useState(null);

  const isTutorial = useMemo(() => brickValues.every(({ type }) => !type), [brickValues]);

  const defineBlockHeight = (index, array) => {
    if (array.length - 1 === index) {
      return 100 - array[array.length - 2];
    } else {
      if (index === 0) {
        return array[index];
      }

      return array[index] - array[index - 1];
    }
  };

  const handleOpenBrickSelector = ({ currentTarget: { dataset } }) => {
    setIsSelectorVisible(true);
    setCurrentSelectedSection(dataset.sectionId);
  };

  const handleCloseBrickSelector = () => {
    setIsSelectorVisible(false);
    setCurrentSelectedSection(null);
  };

  const handleClickOnOption = (type) => () => {
    const newValues = brickValues.slice();

    if (newValues[currentSelectedSection]) {
      newValues[currentSelectedSection].type = type;
    } else {
      newValues[currentSelectedSection] = { type };

      onAddBrick();
    }

    onChangeBrick(newValues);
    handleCloseBrickSelector();
  };

  const handleClickOnRemove = (index) => () => {
    if (values.length > 1) {
      const newValues = [
        ...brickValues.slice(0, index),
        ...brickValues.slice(index + 1, brickValues.length)
      ];
      onChangeBrick(newValues);

      onChangeRange([...redefinePercentages(values.length - 1)]);
      onCommitRange([...redefinePercentages(values.length - 1)]);
    } else {
      const newValues = brickValues.slice();

      newValues[index].type = null;

      onChangeBrick(newValues)
    }
  };

  const handleAddBrick = () => {
    handleOpenBrickSelector({ currentTarget: { dataset: { sectionId: values.length + 1 } } });
  };

  const renderChooseOptions = brickOptions.map(({ label, type, image, isPremium, sizes }) => {
    // eslint-disable-next-line
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const isCurrentOptionActive = brickValues[currentSelectedSection] && brickValues[currentSelectedSection].type === type;

    const handleMouseEnterOption = () => {
      setIsButtonVisible(true);
    };

    const handleMouseLeaveOption = () => {
      setIsButtonVisible(false);
    };

    // соберём все размеры выбранных кирпичей
    let curSizes = [];

    brickValues.forEach((selected) => {
      let brick = brickOptions.find((item) => { 
        return item.type === selected.type; 
      });
      if(brick) {
        brick.sizes.forEach((size) => { 
          if(!curSizes.includes(size)) curSizes.push(size); 
        });
      }
    });

    const isCurrentSizeWDF = curSizes.includes('WDF');
    const isCurrentSizeHandleWDF = curSizes.includes('hndWDF');

    // если выбран кирпич WDF удалим из списка не WDF кирпичи 
    if(isCurrentSizeWDF && !sizes.includes('WDF')) return '';

    // если выбран кирпич hndWDF удалим из списка не hndWDF кирпичи 
    if(isCurrentSizeHandleWDF && !sizes.includes('hndWDF')) return '';

    // если выбран не WDF кирпич удалим из списка WDF кирпичи 
    if(!isCurrentSizeWDF && curSizes.length && sizes.includes('WDF')) return '';

    // если выбран не hndWDF кирпич удалим из списка hndWDF кирпичи 
    if(!isCurrentSizeHandleWDF && curSizes.length && sizes.includes('hndWDF')) return '';

    return (
      <ChooseItemOption
        key={type}
        isActive={isCurrentOptionActive}
        onClick={handleClickOnOption(type)}
        onMouseEnter={handleMouseEnterOption}
        onMouseLeave={handleMouseLeaveOption}
      >
        {isPremium && <KermaPremiumImage src={kermaPremium} />}
        {(isButtonVisible && !isCurrentOptionActive) && <ChooseItemOptionButton>Выбрать</ChooseItemOptionButton>}

        <ChooseItemOptionImage src={image} isActive={isCurrentOptionActive} />

        <ChooseItemOptionText isActive={isCurrentOptionActive}>{label}</ChooseItemOptionText>
      </ChooseItemOption >
    );
  });


  const renderScalesMarks = useMemo(() => scales.map(({ value, label, isBold }) => (
    <ScaleMarkContainer scaleblockheight={value} key={label}>
      <ScaleText>{label}</ScaleText>
      <ScaleMark isbold={isBold} />
    </ScaleMarkContainer>
  )), []);

  const { width } = useWindowSize();

  const STEP = 1;
  const MIN = 10;
  const MAX = 90;

  return (
    isSelectorVisible
      ? (
        <ChooseItemContainer>
          <CustomScrollbar leftPosition='18rem'>
            <ChooseItemOptions>
              {renderChooseOptions}
            </ChooseItemOptions>
          </CustomScrollbar>
        </ChooseItemContainer>
      )
      : (
        <SelectorsMainView>
          <SelectorMainViewContainer
            onMouseUp={onCommitRange}
            onTouchEnd={onCommitRange}
          >
            <Range
              direction={Direction.Down}
              values={values}
              step={STEP}
              min={MIN}
              max={MAX}
              onChange={(newValues) => {
                if (!blockWrongPercentage(newValues)) {
                  onChangeRange(newValues);
                }
              }}
              renderTrack={({ props, children }) => (
                <DragTrack
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  isWithButton={(values.length < 4 && brickValues.every(({ type }) => !!type))}
                  style={{
                    ...props.style,
                  }}
                >
                  <DragTrackInnerBlock
                    ref={props.ref}
                  >
                    {children}
                  </DragTrackInnerBlock>
                </DragTrack>
              )}
              renderThumb={({ props }) => (
                <DragThumbContainer
                  {...props}
                  style={{
                    ...props.style,
                  }}
                >
                  <DragThumb>
                    <img src={dragBrick} alt='drag' />
                  </DragThumb>
                </DragThumbContainer>
              )}
            />

            <SelectedSections>
              {
                [...values, null].map((value, index, array) => {
                  const isSelected = Boolean(brickValues[index].type);
                  const selectedOption = brickOptions.find(({ type }) => type === brickValues[index].type);

                  return (
                    <Fragment key={`${index} ${value}`}>
                      <Tippy
                        arrow={false}
                        visible={index === 0 && isTutorial && width > 1000}
                        placement='right'
                        offset={[0, -100]}
                        maxWidth={207}
                        content={<p>Нажмите, чтобы выбрать кирпич</p>}
                      >
                        <SelectedSection
                          height={defineBlockHeight(index, array)}
                          onClick={isSelected ? () => { } : handleOpenBrickSelector}
                          data-section-id={index}
                        >
                          {isSelected
                            ? (
                              <>
                                <SelectedSectionContent>
                                  <SelectedOptionContainer>
                                    <SelectedOptionImage background={selectedOption.image}>
                                      <SelectedOptionIconBlock data-section-id={index} onClick={handleOpenBrickSelector}>
                                        <img src={changeBrick} alt='change' />
                                      </SelectedOptionIconBlock>

                                      <SelectedOptionIconBlock onClick={handleClickOnRemove(index)}>
                                        <img src={removeBrick} alt='remove' />
                                      </SelectedOptionIconBlock>
                                    </SelectedOptionImage>

                                    <SelectedOptionLabel>{selectedOption.label}</SelectedOptionLabel>
                                  </SelectedOptionContainer>

                                  <PercentOfBrick>
                                    {value
                                      ? index === 0 ? value : value - array[index - 1]
                                      : 100 - array[array.length - 2]}%
                                  </PercentOfBrick>
                                </SelectedSectionContent>

                                {index !== array.length - 1 && (
                                  <TransparentBlock
                                    id='transparent-block'
                                    top={
                                      value
                                        ? index === 0 ? value : value - array[index - 1]
                                        : 100 - array[array.length - 2]
                                    }
                                  />
                                )}
                              </>
                            )
                            : (
                              <>
                                {index !== 0 && (
                                  <TransparentBlock
                                    id='transparent-block'
                                  />
                                )}

                                <SelectedSectionContent>
                                  <ChooseBrickBlock>
                                    <ChooseBrickIconContainer>
                                      <PlusIcon />
                                    </ChooseBrickIconContainer>

                                    <ChooseBrickText>Выберите кирпич</ChooseBrickText>
                                  </ChooseBrickBlock>

                                  <PercentOfBrick>
                                    {value
                                      ? index === 0 ? value : value - array[index - 1]
                                      : 100 - array[array.length - 2]}%
                                  </PercentOfBrick>
                                </SelectedSectionContent>

                                {index !== array.length - 1 && (
                                  <TransparentBlock
                                    id='transparent-block'
                                  />
                                )}
                              </>
                            )
                          }
                        </SelectedSection>
                      </Tippy>
                    </Fragment>
                  );
                })
              }

            </SelectedSections>

            <ScaleBlock isWithButton={(values.length < 4 && brickValues.every(({ type }) => !!type))}>
              {renderScalesMarks}
            </ScaleBlock>
          </SelectorMainViewContainer>

          {
            (values.length < 4 && brickValues.every(({ type }) => !!type)) &&
            <AddButtonBlock onClick={handleAddBrick}>
              <AddButtonBlockText>Добавить кирпич</AddButtonBlockText>

              <ChooseBrickIconContainer>
                <PlusIcon />
              </ChooseBrickIconContainer>
            </AddButtonBlock>
          }
        </SelectorsMainView>
      )
  )
}, equals);
