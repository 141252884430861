import React from 'react';

export const WildBond = () => (
  <svg width="75" height="26" viewBox="0 0 75 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M43 8L33 8V1.45965e-06L43 2.9167e-06V8Z" fill="black" className='marked' />
    <path d="M43 26L33 26V18L43 18V26Z" fill="black" className='marked' />
    <path d="M57 17L47 17V9L57 9V17Z" fill="black" className='marked' />
    <path d="M28 17L18 17V9L28 9V17Z" fill="black" className='marked' />
    <path d="M61 8L44 8V0L61 1.48619e-06V8Z" fill="black" className='main' />
    <path d="M61 26L44 26V18L61 18V26Z" fill="black" className='main' />
    <path d="M75 17L58 17V9L75 9V17Z" fill="black" className='main' />
    <path d="M17 17L0 17L6.99382e-07 9L17 9V17Z" fill="black" className='main' />
    <path d="M32 8L15 8L15 0L32 1.48619e-06V8Z" fill="black" className='main' />
    <path d="M32 26L15 26L15 18L32 18V26Z" fill="black" className='main' />
    <path d="M46 17L29 17V9L46 9V17Z" fill="black" className='main' />
  </svg>
);
