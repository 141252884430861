import React from 'react';
import pdfLogo from './pdf-logo.png';
import {
  HeaderContainer, HeaderLogoBlock, HeaderLogo, HeaderTextBlock,
  HeaderTextTitle, HeaderTextAddress, HeaderTextContacts,
} from './styled';

export const Header = () => (
  <HeaderContainer>
    <HeaderLogoBlock>
      <HeaderLogo src={pdfLogo} />
    </HeaderLogoBlock>

    <HeaderTextBlock>
      <HeaderTextTitle>Контактная информация:</HeaderTextTitle>

      <HeaderTextAddress>Ленинградская область, Всеволожский район, пос. им. Свердлова, микрорайон №2, 15</HeaderTextAddress>

      <HeaderTextContacts>8 (812) 920-23-70, 8 (925) 049-86-37</HeaderTextContacts>
    </HeaderTextBlock>
  </HeaderContainer>
);