import React from 'react';

export const StandartSeamSize = () => (
  <svg width="74" height="28" viewBox="0 0 74 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M54 8L37 8V0H54V8Z" fill="black" />
    <path d="M26 10H9V18H26V10Z" fill="black" />
    <path d="M0 20H17V28H0V20Z" fill="black" />
    <path d="M19 20H36V28H19V20Z" fill="black" />
    <path d="M57 20H74V28H57V20Z" fill="black" />
    <path d="M64 10H47V18H64V10Z" fill="black" />
    <path d="M18 8L35 8V0H18V8Z" fill="black" />
    <path d="M45 18L28 18V10L45 10V18Z" fill="black" />
    <path d="M55 20H38V28H55V20Z" fill="black" />
  </svg>
);
