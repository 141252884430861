import { brickOptions } from 'components/BrickSelector/options';

export const redefinePercentages = (newArrayLength) => {
  const newPercentage = Math.round(100 / (newArrayLength + 1));

  const newPercentages = new Array(newArrayLength)
    .fill(newPercentage)
    .map((item, index) => {
      if (index === 0) {
        return item;
      }

      return item * (index + 1);
    });

  return newPercentages.slice(0, newArrayLength);
};

export const defineBricksPercentage = (brickValues, brickRanges, brickSize) => brickValues.map(({ type }, index, array) => {
  const brickOption = brickOptions.find(({ type: brickValue }) => brickValue === type);

  const imagesPaths = (() => {
    if (brickOption) {
      return brickSize === '1.4'
        ? brickOption.imagesPaths14
        : brickOption.imagesPaths;
    }

    return [];
  })();

  let percent = 0;

  if (brickRanges.length === 1) {
    percent = index === 0 ? brickRanges[0] : 100 - brickRanges[0];
  } else {
    if (index === 0) {
      percent = brickRanges[0];
    } else if (array.length - 1 === index) {
      percent = 100 - brickRanges[brickRanges.length - 1];
    } else {
      percent = brickRanges[index] - brickRanges[index - 1];
    }
  }

  return {
    type,
    percent,
    imagesPaths,
  };
});

export const bricksSizesByFormat = {
  '0.5': {
    height: 22,
    width: 84,
  },
  '0.7': {
    height: 22,
    width: 84,
  },
  'WDF': {
    height: 22,
    width: 72,
  },
  'hndWDF': {
    height: 22,
    width: 72,
  },
};

export const getWildPredefinedWidths = (brickWidth, brickPokeWidth) => {
  return [
    [1, 5, 1, 5, 1, 5, 1, 1, 5, 1, 1, 1, 5, 1, 1, 1, 5, 1, 5, 1, 5, 1, 1, 5, 5],
    [5, 5, 1, 1, 1, 5, 1, 1, 1, 5, 1, 5, 1, 1, 1, 5, 5, 5, 1, 1, 1, 5, 1, 1, 1],
    [1, 5, 1, 5, 1, 1, 5, 5, 1, 1, 1, 1, 5, 1, 1, 1, 5, 1, 5, 1, 1, 5, 5, 1, 1],
    [5, 1, 5, 1, 5, 1, 5, 1, 1, 1, 1, 5, 1, 1, 5, 1, 5, 1, 5, 1, 5, 1, 5, 1, 1],
    [1, 1, 5, 1, 1, 1, 5, 1, 1, 1, 5, 1, 1, 5, 1, 1, 5, 1, 1, 5, 1, 1, 1, 5, 1],

    [5, 1, 1, 1, 5, 1, 1, 1, 5, 5, 1, 5, 1, 5, 1, 1, 5, 1, 1, 1, 5, 1, 1, 1, 1],
    [1, 5, 1, 1, 5, 5, 1, 1, 1, 5, 1, 5, 1, 1, 1, 1, 5, 1, 1, 5, 5, 1, 1, 1, 1],
    [5, 1, 5, 1, 5, 1, 1, 5, 5, 1, 1, 5, 1, 1, 1, 1, 5, 1, 5, 1, 5, 1, 1, 5, 5],
    [1, 5, 1, 1, 5, 1, 1, 5, 1, 5, 1, 1, 5, 1, 1, 1, 5, 1, 1, 5, 1, 1, 5, 1, 5],
    [5, 5, 5, 1, 1, 1, 1, 1, 5, 1, 1, 1, 1, 5, 1, 5, 5, 5, 5, 1, 1, 1, 1, 1, 5],

    [1, 1, 1, 5, 1, 1, 1, 5, 1, 1, 5, 1, 1, 1, 1, 1, 1, 5, 1, 1, 1, 5, 5, 1, 5],
    [5, 1, 1, 1, 1, 5, 1, 5, 1, 1, 1, 5, 1, 5, 5, 1, 5, 1, 1, 1, 1, 5, 1, 5, 1],
    [5, 1, 1, 5, 1, 1, 5, 1, 1, 5, 1, 1, 5, 1, 1, 5, 1, 1, 5, 1, 1, 5, 1, 1, 5],
    [5, 1, 1, 1, 5, 1, 1, 1, 5, 1, 1, 1, 1, 5, 1, 5, 1, 1, 1, 5, 1, 1, 1, 1, 1],
    [1, 5, 5, 1, 1, 5, 1, 5, 1, 1, 1, 5, 1, 1, 1, 1, 5, 5, 1, 1, 5, 1, 5, 1, 1],

    [5, 5, 1, 1, 5, 1, 1, 1, 5, 1, 1, 5, 1, 1, 5, 1, 5, 5, 1, 1, 5, 1, 1, 1, 5],
    [1, 1, 5, 1, 5, 1, 1, 1, 5, 1, 1, 1, 5, 5, 1, 1, 1, 5, 1, 5, 1, 1, 1, 1, 5],
    [5, 1, 5, 1, 5, 1, 1, 5, 1, 1, 1, 5, 1, 1, 5, 5, 1, 5, 1, 5, 1, 1, 5, 1, 1],
    [5, 1, 5, 1, 1, 1, 1, 5, 1, 1, 5, 5, 1, 1, 1, 5, 1, 5, 1, 1, 1, 1, 5, 1, 5],
    [5, 1, 1, 1, 5, 1, 5, 1, 1, 5, 1, 1, 1, 5, 5, 1, 5, 1, 1, 1, 5, 1, 5, 1, 1]
  ].map((item) => {
    return item.map((brick) => brick === 5 ? brickPokeWidth : brickWidth);
  });
};