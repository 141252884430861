import styled, { css } from 'styled-components';
import { ifProp, ifNotProp } from 'styled-tools';

export const SizeSelectorContainer = styled.div`
  background: #ECECEC;

  margin: .75rem .75rem .8125rem .9375rem;

  height: calc(100% - 1.5675rem);
  width: 100%;
`;

export const SizeOptions = styled.ul`
  padding: 0;
  margin: 0;
`;

export const SizeIsNotAvailable = styled.div`
  position: absolute;
  z-index: 99;
  background: rgba(128, 128, 128, 0.3);
  width: 17.9375rem;
  height: 4.875rem;
  cursor: not-allowed;

  @media screen and (max-width: 1000px) {
    height: 4.9375rem;
    width: calc(100% - 27px);
  }
`;

export const SizeOption = styled.li`
  list-style-type: none;

  cursor: pointer;

  display: flex;
  height: 4.875rem;
  align-items: center;
  border-bottom: 1px solid rgb(61, 61, 68, 0.5);

  ${ifNotProp({ isActive: true }, css`
    &:hover {
      background: #D4EAE2;
    }
  `)}
`;

export const SizeOptionIconContainer = styled.div`
  flex-basis: 9.9375rem;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: #8b8b8e;
  }

  ${ifProp({ isActive: true }, css`
    svg path {
      fill: #C8AF64;
    }
  `)}
`;

export const SizeOptionText = styled.div`
  display: flex;
  flex-direction: column;

  flex-basis: 8rem;
  flex-grow: 0;
  flex-shrink: 0;
`;

//margin-top: 1.375rem;
export const SizeOptionFirstLabel = styled.p`
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;

  color: #000000;
  margin: 0;
  
  ${ifProp({ isActive: true }, css`
    color: #C8AF64;
  `)}
`;

export const SizeOptionSecondLabel = styled(SizeOptionFirstLabel)`
  margin-top: 0.4375rem;
`;

export const ChooseSizeButton = styled.button`
  width: 5rem;
  height: 1.5rem;
  border: none;
  outline: none;

  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;

  color: #FFFFFF;

  background: #C8AF64;
  border-radius: 0.1875rem;

  cursor: pointer;
  position: absolute;
`;
