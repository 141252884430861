import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 103px;
  border-bottom: 1px solid #C8AF64;
  box-sizing: border-box;

  margin-left: 153px;
  margin-right: 41px;

  margin-bottom: 40px;

  display: flex;
`;

export const HeaderLogoBlock = styled.div`
  margin-top: 47px;
  margin-right: 117px;
`;

export const HeaderLogo = styled.img`
  margin-left: -106px;
  margin-top: -6px
`;

export const HeaderTextBlock = styled.div`
  margin-top: 43px;
  margin-bottom: 14px;
`;

export const HeaderTextTitle = styled.h3`
  margin: 0;
  font-family: PT Serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;

  margin-bottom: 4px;
`;

export const HeaderTextAddress = styled.p`
  padding: 0;
  margin: 0;
  font-family: PT Serif;
  font-style: normal;
  font-size: 12px;
  line-height: 100%;

  margin-bottom: 4px;
`;

export const HeaderTextContacts = styled.p`
  padding: 0;
  margin: 0;
  font-family: PT Serif;
  font-style: normal;
  font-size: 12px;
  line-height: 100%;
`;
