export const blockWrongPercentage = (ranges) => {
  let isBlocked = false;

  ranges.forEach((current, index, array) => {
    if (array[index + 1]) {
      if (array[index + 1] - current < 10) isBlocked = true;
    }
  });

  return isBlocked;
};

