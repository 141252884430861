import styled from 'styled-components';

export const ScrollbarTrack = styled.div`
  height: 100%;
  left: 18rem;
`;

export const ScrollbarThumb = styled.div`
  &&& {
    width: .625rem !important;

    background: #C8AF64;
    border-radius: .375rem;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: .4375rem;
    }
  }
`;
