import React from 'react';

export const SizeIconWDF = () => (
  <svg width="79" height="26" viewBox="0 0 79 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M 31,8 H 16 V 0 l 15,2.24109e-6 z" fill="black"/>
    <path d="M 32,8 H 47 V 3.8147e-6 L 32,2.32851e-6 Z" fill="black"/>
    <path d="M 23,9 H 8 v 8 h 15 z" fill="black"/>
    <path d="m 0,18 h 15 v 8 H 0 Z" fill="black"/>
    <path d="m 16,18 h 15 v 8 H 16 Z" fill="black"/>
    <path d="M 24,17 H 39 V 9 H 24 Z" fill="black"/>
    <path d="m 62.999774,8 h -15 V 0 l 15,2.24109e-6 z" fill="black"/>
    <path d="M 55,9 H 40 v 8 h 15 z" fill="black"/>
    <path d="m 32,18 h 15 v 8 H 32 Z" fill="black"/>
    <path d="m 48,18 h 15 v 8 H 48 Z" fill="black"/>
    <path d="m 64,18 h 15 v 8 H 64 Z" fill="black"/>
    <path d="M 56,17 H 71 V 9 H 56 Z" fill="black"/>
  </svg>
);
