import React, { useMemo, useCallback, useContext, memo } from 'react';
import { MobileFooterContainer, MobileFooterInner } from './styled';
import { AppContext } from 'App';
import { navigationButtons } from 'components/Navigation/steps';
import { NavigationItem, NavigationButton, ButtonLogo, ButtonText, NavigationButtons } from 'components/Navigation/styled';

export const MobileFooter = memo(() => {
  const { setCurrentPage, currentPage, handleChangeIsMobileTutorial, handleChangeIsViewerVisible } = useContext(AppContext);

  const handleNavigate = useCallback((e) => {
    const { dataset: { navigationValue } } = e.currentTarget;

    setCurrentPage(Number(navigationValue));

    handleChangeIsMobileTutorial(false);
    handleChangeIsViewerVisible(false);
  }, [setCurrentPage, handleChangeIsMobileTutorial, handleChangeIsViewerVisible]);

  const renderButtons = useMemo(
    () => navigationButtons.map((button, index) => (
      <NavigationItem key={button.label}>
        <NavigationButton
          data-navigation-value={button.value}
          active={currentPage === button.value}
          fillIcon={index !== 2}
          onClick={handleNavigate}
        >
          <ButtonLogo>
            {button.logo}
          </ButtonLogo>

          <ButtonText>
            {button.label}
          </ButtonText>
        </NavigationButton>
      </NavigationItem>
    )),
    [currentPage, handleNavigate],
  );

  return (
    <MobileFooterContainer>
      <MobileFooterInner>
        <NavigationButtons>
          {renderButtons}
        </NavigationButtons>
      </MobileFooterInner>
    </MobileFooterContainer>
  );
});
