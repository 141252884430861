import React from 'react';
import { BrickLogo } from './logo/BrickLogo';
import { SizeLogo } from './logo/SizeLogo';
import { SeamLogo } from './logo/SeamLogo';
import { BondLogo } from './logo/BondLogo';

export const navigationButtons = [
  {
    label: 'Кирпич',
    logo: <BrickLogo />,
    value: 0,
  },
  {
    label: 'Размер',
    logo: <SizeLogo />,
    value: 1,
  },
  {
    label: 'Шов',
    logo: <SeamLogo />,
    value: 2,
  },
  {
    label: 'Перевязка',
    logo: <BondLogo />,
    value: 3,
  }
];
