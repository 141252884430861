import React, { Fragment, useState, useCallback, memo } from 'react';
import {
  SeamTypeSelectorContainer, SeamColorSelectorContainer, SeamSelectors,
  SeamSelectorHeader, SeamSelectorHeaderText, SeamOption, SeamOptionIconContainer,
  ChooseSeamTypeButton, SeamOptionText, SeamOptionLabel, SeamTypeOptions, SeamColorOptions,
  SeamColor, SeamColorText, SeamColorButton, SeamColorOption, SeamColorButtonContainer,
  SeamIsNotAvailable
} from './styled';
import { seamTypesOptions, seamColorOptions } from './options';
import CustomScrollbar from 'components/CustomScrollbar';
import equals from 'ramda/es/equals';
import { brickOptions } from '../BrickSelector/options';

export const SeamSelector = memo(({ currentSeamType, currentSeamColor, onChangeSeamType, onChangeSeamColor, brickValues }) => {
  const handleClickOnSeamTypeOption = useCallback((seamType) => () => {
    onChangeSeamType(seamType);
  }, [onChangeSeamType]);

  const handleClickOnSeamColorOptions = useCallback((seamColor) => () => {
    onChangeSeamColor(seamColor);
  }, [onChangeSeamColor]);

  const renderSeamTypeOptions = seamTypesOptions.map(({ icon, label, value }) => {
    // eslint-disable-next-line
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const isActiveOption = value === currentSeamType;

    const handleMouseEnterOption = () => {
      setIsButtonVisible(true);
    };

    const handleMouseLeaveOption = () => {
      setIsButtonVisible(false);
    };

    // соберём все размеры выбранных кирпичей
    let curSizes = [];

    brickValues.forEach((selected) => {
      let brick = brickOptions.find((item) => { 
        return item.type === selected.type; 
      });
      if(brick) {
        brick.sizes.forEach((size) => { 
          if(!curSizes.includes(size)) curSizes.push(size); 
        });
      }
    });

    // если выбран кирпич hndWDF запретим минишов и выберим стандарт 
    let isOptionNotAvailable = curSizes.includes('hndWDF') && value !== 'standart';

    return (
      <Fragment key={value}>
        {isOptionNotAvailable && <SeamIsNotAvailable />}
        <SeamOption
          //key={value}
          isActive={isActiveOption}
          onClick={handleClickOnSeamTypeOption(value)}
          onMouseEnter={handleMouseEnterOption}
          onMouseLeave={handleMouseLeaveOption}
        >
          <SeamOptionIconContainer isActive={isActiveOption}>
            {(isButtonVisible && !isActiveOption) && <ChooseSeamTypeButton>Выбрать</ChooseSeamTypeButton>}
            {icon}
          </SeamOptionIconContainer>

          <SeamOptionText>
            <SeamOptionLabel isActive={isActiveOption}>{label}</SeamOptionLabel>
          </SeamOptionText>
        </SeamOption>
      </Fragment>
    );
  });

  const renderSeamColorOptions = seamColorOptions.map(({ image, colorCode }) => {
    // eslint-disable-next-line
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const isActiveOption = colorCode === currentSeamColor;

    const handleMouseEnterOption = () => {
      setIsButtonVisible(true);
    };

    const handleMouseLeaveOption = () => {
      setIsButtonVisible(false);
    };

    return (
      <SeamColorOption
        key={colorCode}
        isActive={isActiveOption}
        onClick={handleClickOnSeamColorOptions(colorCode)}
        onMouseEnter={handleMouseEnterOption}
        onMouseLeave={handleMouseLeaveOption}
      >
        <SeamColor seamImage={image} isActive={isActiveOption} />

        <SeamColorText isActive={isActiveOption}>{colorCode}</SeamColorText>

        <SeamColorButtonContainer>
          {(isButtonVisible && !isActiveOption) && <SeamColorButton>Выбрать</SeamColorButton>}
        </SeamColorButtonContainer>
      </SeamColorOption>
    );
  });

  return (
    <SeamSelectors>
      <SeamTypeSelectorContainer>
        <SeamSelectorHeader>
          <SeamSelectorHeaderText>
            Тип шва
        </SeamSelectorHeaderText>
        </SeamSelectorHeader>

        <SeamTypeOptions>
          {renderSeamTypeOptions}
        </SeamTypeOptions>
      </SeamTypeSelectorContainer>


      <CustomScrollbar leftPosition='19rem'>
        <SeamColorSelectorContainer>
          <SeamSelectorHeader>
            <SeamSelectorHeaderText>
              Цвет
        </SeamSelectorHeaderText>
          </SeamSelectorHeader>
          <SeamColorOptions>
            {renderSeamColorOptions}
          </SeamColorOptions>
        </SeamColorSelectorContainer>
      </CustomScrollbar>
    </SeamSelectors>
  );
}, equals);
