import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { createBrickLayout } from 'createBrickLayout';
import { copyCanvas, renderMarks } from 'brickLayoutHelpers';

export const createPDF = async (valuesForBricks, brickSize, seamSize, seamImage, bondType) => {
  const doc = new jsPDF({
    unit: 'in',
  });

  const firstCanvas = document.querySelector('#first-page-canvas');
  const secondCanvas = document.querySelector('#second-page-canvas');

  const marksData = await createBrickLayout(firstCanvas, valuesForBricks, brickSize, seamSize, seamImage, bondType, true);

  copyCanvas(secondCanvas, firstCanvas);
  renderMarks(secondCanvas, marksData);

  const firstImage = await html2canvas(document.querySelectorAll('#pdf-container > div')[0]);
  const secondImage = await html2canvas(document.querySelectorAll('#pdf-container > div')[1]);

  doc.addImage(firstImage.toDataURL('image/png'), 'PNG', 0, 0, 8, 10, '1', 'FAST');

  doc.addPage();

  doc.addImage(secondImage.toDataURL('image/png'), 'PNG', 0, 0, 8, 10, '2', 'FAST');

  doc.save("download.pdf");
};
