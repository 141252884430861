import React from 'react';
import { MinSeamSize } from './icons/MinSeamSize';
import { StandartSeamSize } from './icons/StandartSeamSize';
import image010 from './images/010.jpg';
import image011 from './images/011.jpg';
import image020 from './images/020.jpg';
import image021 from './images/021.jpg';
import image022 from './images/022.jpg';
import image023 from './images/023.jpg';
import image026 from './images/026.jpg';
import image027 from './images/027.jpg';
import image028 from './images/028.jpg';
import image030 from './images/030.jpg';
import image034 from './images/034.jpg';
import image035 from './images/035.jpg';
import image036 from './images/036.jpg';
import image040 from './images/040.jpg';
import image041 from './images/041.jpg';
import image045 from './images/045.jpg';
import image046 from './images/046.jpg';
import image061 from './images/061.jpg';
import image070 from './images/070.jpg';
import image071 from './images/071.jpg';
import image073 from './images/073.jpg';
import image083 from './images/083.jpg';

export const seamTypesOptions = [
  {
    icon: <MinSeamSize />,
    label: 'Минимальный',
    value: 'min',
  },
  {
    icon: <StandartSeamSize />,
    label: 'Стандарт',
    value: 'standart',
  }
];

export const seamColorOptions = [
  {
    image: image010,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/010.jpg`,
    colorCode: '010',
  },
  {
    image: image011,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/011.jpg`,
    colorCode: '011',
  },
  {
    image: image020,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/020.jpg`,
    colorCode: '020',
  },
  {
    image: image021,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/021.jpg`,
    colorCode: '021',
  },
  {
    image: image022,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/022.jpg`,
    colorCode: '022',
  },
  {
    image: image023,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/023.jpg`,
    colorCode: '023',
  },
  {
    image: image026,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/026.jpg`,
    colorCode: '026',
  },
  {
    image: image027,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/027.jpg`,
    colorCode: '027',
  },
  {
    image: image028,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/028.jpg`,
    colorCode: '028',
  },
  {
    image: image030,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/030.jpg`,
    colorCode: '030',
  },
  {
    image: image034,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/034.jpg`,
    colorCode: '034',
  },
  {
    image: image035,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/035.jpg`,
    colorCode: '035',
  },
  {
    image: image036,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/036.jpg`,
    colorCode: '036',
  },
  {
    image: image040,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/040.jpg`,
    colorCode: '040',
  },
  {
    image: image041,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/041.jpg`,
    colorCode: '041',
  },
  {
    image: image045,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/045.jpg`,
    colorCode: '045',
  },
  {
    image: image046,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/046.jpg`,
    colorCode: '046',
  },
  {
    image: image061,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/061.jpg`,
    colorCode: '061',
  },
  {
    image: image070,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/070.jpg`,
    colorCode: '070',
  },
  {
    image: image071,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/071.jpg`,
    colorCode: '071',
  },
  {
    image: image073,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/073.jpg`,
    colorCode: '073',
  },
  {
    image: image083,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/083.jpg`,
    colorCode: '083',
  },
];