import React from 'react';
import { Viewer } from './';

import { seamColorOptions } from 'components/SeamSelector/options';
import { defineBricksPercentage } from 'brickSizes';

export class ViewerController extends React.Component {
  shouldComponentUpdate(prevProps) {
    const c = this.props.context;
    const pC = prevProps.context;

    return c.brickValues !== pC.brickValues ||
      c.totalBrickRanges !== pC.totalBrickRanges ||
      c.currentSeamType !== pC.currentSeamType ||
      c.currentSeamColor !== pC.currentSeamColor ||
      c.currentBond !== pC.currentBond ||
      c.currentSize !== pC.currentSize ||
      c.isMobileTutorial !== pC.isMobileTutorial;
  }

  render() {
    const {
      brickValues,
      totalBrickRanges: brickRanges,
      currentSeamType: seamSize,
      currentSeamColor: seamColor,
      currentBond: bondType,
      currentSize: brickSize,

      isMobileTutorial,
    } = this.props.context;

    const seamImage = seamColorOptions.find(({ colorCode }) => colorCode === seamColor).imagePath;
    const valuesForBricks = defineBricksPercentage(brickValues, brickRanges, brickSize);

    return <Viewer
      seamSize={seamSize}
      bondType={bondType}
      isMobileTutorial={isMobileTutorial}
      seamImage={seamImage}
      valuesForBricks={valuesForBricks}
      brickSize={brickSize}
      brickValues={brickValues}
    />
  }
};
