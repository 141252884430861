import React from 'react';

export const SizeIcon07nf = () => (
  <svg width="107" height="26" viewBox="0 0 107 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35 8L18 8L18 0L35 2.24109e-06V8Z" fill="black" />
    <path d="M36 8L53 8V3.8147e-06L36 2.32851e-06V8Z" fill="black" />
    <path d="M26 9H9V17H26V9Z" fill="black" />
    <path d="M0 18H17V26H0V18Z" fill="black" />
    <path d="M18 18H35V26H18V18Z" fill="black" />
    <path d="M36 18H53V26H36V18Z" fill="black" />
    <path d="M71 18H54V26H71V18Z" fill="black" />
    <path d="M45 9H62V17H45V9Z" fill="black" />
    <path d="M27 17L44 17V9L27 9V17Z" fill="black" />
    <path d="M71 8L54 8V0L71 2.24109e-06V8Z" fill="black" />
    <path d="M72 8L89 8V3.8147e-06L72 2.32851e-06V8Z" fill="black" />
    <path d="M62 9H45V17H62V9Z" fill="black" />
    <path d="M36 18H53V26H36V18Z" fill="black" />
    <path d="M54 18H71V26H54V18Z" fill="black" />
    <path d="M72 18H89V26H72V18Z" fill="black" />
    <path d="M107 18H90V26H107V18Z" fill="black" />
    <path d="M81 9H98V17H81V9Z" fill="black" />
    <path d="M63 17L80 17V9L63 9V17Z" fill="black" />
  </svg>
);
