import styled from 'styled-components';
import { NavigationButtons, NavigationButton, ButtonText } from 'components/Navigation/styled';

export const MobileFooterContainer = styled.div`
  display: none;
  flex-basis: 3.4375rem;
  max-height: 3.4375rem;

  flex-shrink: 0;
  flex-grow: 0;
  background: linear-gradient(180deg, #78817C 0%, #78817C 100%);

  justify-content: center;

  @media screen and (max-width: 1000px) {
    display: flex; 
  }
`;

export const MobileFooterInner = styled.div`
  width: 23.4375rem;
  height: 100%;

  ${NavigationButtons} {
    display: flex;
    margin: 0;
  }

  ${NavigationButton} div {
    margin-top: .6875rem;
  }

  ${ButtonText} {
    font-size: .5625rem;
  }
`;
