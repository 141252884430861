import React, { useMemo, useRef, useEffect, useCallback, useState, memo } from 'react';
import { default as VendorLoader } from 'react-loader-spinner'
import { useWindowSize } from 'hooks/useWindowSize';
import {
  ViewerContainer, TutorialContainer, TutorialHeading, TutorialDescription, Developer,
  TutorialActionsList, TutorialAction, TutorialActionIcon, TutorialActionText, HouseImage, Viewer3dRoot,
  TutorialClarificationBlock, TutorialClarification, TutorialActionIconContainer, ViewerCanvas, House,
  Cube11, CubeWrapper, CanvasImg11, CanvasImg12, Cube12, CanvasImg13, CanvasImg14, Cube13, CanvasImg15, CanvasImg16,
  ArrowLeft, ArrowRight, /*ModeWrapper, ModeButton,*/ Cube21, Cube22, Cube23, Cube24, Cube25, Cube31, Cube32,
  CanvasImg21, CanvasImg22, CanvasImg23, CanvasImg24, CanvasImg25, CanvasImg26, CanvasImg27, CanvasImg28, CanvasImg29, CanvasImg210,
  CanvasImg31, CanvasImg32, CanvasImg33, CanvasImg34, House2Image, House3Image, Cube14, CanvasImg17, CanvasImg18, CubesWrapper, CubesWrapper2, CubesWrapper3
} from './styled';

import selectBrick from './icons/select-brick.png';
import changeBrick from './icons/change-brick.png';
import dragBrick from './icons/drag-brick.png';
import removeBrick from './icons/remove-brick.png';
import house1 from './images/h1.png';
import house2 from './images/h3.png';
import house3 from './images/h2.png';

import { createBrickLayout } from 'createBrickLayout';
import { LoaderContainer, LoaderText } from 'components/Navigation/Loader/styled';

export const Viewer = memo((props) => {
  const {
    seamSize,
    bondType,
    isMobileTutorial,
    seamImage,
    valuesForBricks,
    brickSize,
    brickValues,
  } = props;

  const canvasRef = useRef(null);

  const [imageData, setImageData] = useState();
  const viewerContainerRef = useRef(null);

  // const [mode, setMode] = useState('2d');
  const mode = '2d';

  const [house, setHouse] = useState(0);
  const [preloader, setPreloader] = useState(false);

  const { height } = useWindowSize();
  const isTutorial = useMemo(() => brickValues.every(({ type }) => !type), [brickValues]);

  const layoutTimer = useRef(0);

  const actions = [
    {
      icon: selectBrick,
      text: 'Нажмите, чтобы выбрать кирпич (до 5 видов кирпича)',
    },
    {
      icon: changeBrick,
      text: 'Нажмите, чтобы изменить кирпич',
    },
    {
      icon: dragBrick,
      text: 'Перетащите чтобы изменить процентное соотношение',
    },
    {
      icon: removeBrick,
      text: 'Нажмите, чтобы удалить кирпич',
    }
  ];

  const renderTutorialActions = useMemo(
    () => actions.map(({ icon, text }, index) =>
      <TutorialAction key={index}>
        <TutorialActionIconContainer>
          <TutorialActionIcon src={icon} />
        </TutorialActionIconContainer>

        <TutorialActionText>{text}</TutorialActionText>
      </TutorialAction>
    ),
    [actions],
  );

  const setCanvasSizes = useCallback(() => {
    if (canvasRef.current && viewerContainerRef.current) {
      canvasRef.current.width = mode === '3d' ? 4000 : viewerContainerRef.current.offsetWidth;
      canvasRef.current.height = mode === '3d' ? 2160 : viewerContainerRef.current.offsetHeight;
    }
    // eslint-disable-next-line
  }, [canvasRef.current, viewerContainerRef.current, mode]);

  useEffect(
    () => {
      setCanvasSizes();
    },
    // eslint-disable-next-line
    [height, isTutorial, setCanvasSizes, mode],
  );

  useEffect(() => {
    if (!isTutorial && !isMobileTutorial && height) {
      window.clearTimeout(layoutTimer.current);
      layoutTimer.current = window.setTimeout(async () => {
        setPreloader(true);

        await createBrickLayout(canvasRef.current, valuesForBricks, brickSize, seamSize, seamImage, bondType, false, mode === '3d');

        mode === '3d' && canvasRef.current.toBlob(blob => {
          const link = URL.createObjectURL(blob);
          setImageData(link);
        }, 'image/jpeg', 0.95);

      }, 100);
    }
    // eslint-disable-next-line
  }, [isTutorial, bondType, brickSize, seamSize, seamImage, height, JSON.stringify(valuesForBricks), setCanvasSizes, mode]);

  useEffect(() => {
    setPreloader(false);
  }, [imageData]);

  return (
    <ViewerContainer ref={viewerContainerRef} isMobileTutorial={isMobileTutorial}>
      {(isTutorial || isMobileTutorial) && (
          <TutorialContainer>
            <TutorialHeading>Модуль &quot;Смешивай и комбинируй&quot;</TutorialHeading>

            <TutorialDescription>
              Faber Jar предлагает не только преимущества широкого выбора кирпича, но и возможность <br />
              комбинировать их для создания уникальных сочетаний. Это полная свобода творчества! <br />
              <br />
              Возможности бесконечны!
            </TutorialDescription>

            <TutorialActionsList>
              {renderTutorialActions}
            </TutorialActionsList>

            {/* <TutorialClarificationBlock>
              <TutorialClarification>
                В режиме 2D Вы сможете увидеть сочетания различных типов кладки, выбранных кирпичей и швов в укрупненном виде. <br />
                А в режиме 3Dom данное сочетание можно увидеть на стене в различных типах домов.
              </TutorialClarification>
            </TutorialClarificationBlock> */}

            <TutorialClarificationBlock>
              <TutorialClarification>Когда ваша комбинация будет готова, вы сможете сохранить ее в формате PDF .</TutorialClarification>
            </TutorialClarificationBlock>
          </TutorialContainer>
        )
      }

      {!(isTutorial || isMobileTutorial) && (
          <>
            <ViewerCanvas
              width={1920}
              height={1080}
              ref={canvasRef}
              style={mode === '3d' ? { visibility: 'hidden' } : {}}
            />
            {mode === '3d' &&
              <>
                <ArrowLeft onClick={() => setHouse(house === 0 ? 2 : (house - 1))} />

                <ArrowRight onClick={() => setHouse(house === 2 ? 0 : (house + 1))} />
              </>
            }
            {/* <ModeWrapper>
              <ModeButton active={mode === '2d'} onClick={() => setMode('2d')}>2D</ModeButton>
              <ModeButton active={mode === '3d'} onClick={() => setMode('3d')}>3Dom</ModeButton>
            </ModeWrapper> */}
          </>
        )
      }

      <div style={(!(isTutorial || isMobileTutorial) && mode === '3d') ? { visibility: 'visible' } : { visibility: 'hidden' }}>
        <Viewer3d canvasRef={canvasRef} mode={mode} house={house} imageData={imageData} preloader={preloader} />
      </div>

      <Developer>
        Разработчик <a href="https://pahomov.pro">pahomov.pro</a>
      </Developer>
    </ViewerContainer>
  );
});

class Viewer3d extends React.PureComponent {
  render() {
    const { house, imageData, preloader, mode } = this.props;

    return preloader ? (
      <LoaderContainer>
        <VendorLoader
          type='Rings'
          color='#C8AF64'
          height={100}
          width={100}
        />

        <LoaderText>Loading</LoaderText>
      </LoaderContainer>
    ) : (
      <Viewer3dRoot>
        <House3 image={house2} visible={house === 2 && mode === '3d'} />
        <House2 image={house3} visible={house === 1 && mode === '3d'} />
        <House1 image={house1} visible={house === 0 && mode === '3d'} />
      </Viewer3dRoot>
    );

    function House1(props) {
      return (
        <House visible={props.visible}>
          <CubesWrapper>
            <CubeWrapper>
              <Cube11>
                <CanvasImg11 src={imageData} />
                <CanvasImg12 src={imageData} />
              </Cube11>
            </CubeWrapper>

            <CubeWrapper>
              <Cube12>
                <CanvasImg13 src={imageData} />
                <CanvasImg14 src={imageData} />
              </Cube12>
            </CubeWrapper>

            <CubeWrapper>
              <Cube13>
                <CanvasImg15 src={imageData} />
                <CanvasImg16 src={imageData} />
              </Cube13>
            </CubeWrapper>

            <CubeWrapper>
              <Cube14>
                <CanvasImg17 src={imageData} />
                <CanvasImg18 src={imageData} />
              </Cube14>
            </CubeWrapper>
          </CubesWrapper>

          <HouseImage>
            <img src={props.image} alt="house 1" />
          </HouseImage>
        </House>
      );
    }

    function House2(props) {
      return (
        <House visible={props.visible}>
          <CubesWrapper2>
            <CubeWrapper>
              <Cube21>
                <CanvasImg21 src={imageData} />
                <CanvasImg22 src={imageData} />
              </Cube21>
            </CubeWrapper>

            <CubeWrapper>
              <Cube22>
                <CanvasImg23 src={imageData} />
                <CanvasImg24 src={imageData} />
              </Cube22>
            </CubeWrapper>

            <CubeWrapper>
              <Cube23>
                <CanvasImg25 src={imageData} />
                <CanvasImg26 src={imageData} />
              </Cube23>
            </CubeWrapper>

            <CubeWrapper>
              <Cube24>
                <CanvasImg27 src={imageData} />
                <CanvasImg28 src={imageData} />
              </Cube24>
            </CubeWrapper>

            <CubeWrapper>
              <Cube25>
                <CanvasImg29 src={imageData} />
                <CanvasImg210 src={imageData} />
              </Cube25>
            </CubeWrapper>
          </CubesWrapper2>

          <House2Image>
            <img src={props.image} alt="house 2" />
          </House2Image>
        </House>
      );
    }

    function House3(props) {
      return (
        <House visible={props.visible}>
          <CubesWrapper3>
            <CubeWrapper>
              <Cube31>
                <CanvasImg31 src={imageData} />
                <CanvasImg32 src={imageData} />
              </Cube31>
            </CubeWrapper>

            <CubeWrapper>
              <Cube32>
                <CanvasImg33 src={imageData} />
                <CanvasImg34 src={imageData} />
              </Cube32>
            </CubeWrapper>
          </CubesWrapper3>

          <House3Image>
            <img src={props.image} alt="house 3" />
          </House3Image>
        </House>
      );
    }
  }
}
