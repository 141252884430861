const actions = (dispatch) => ({
  setCurrentPage: (currentPage) => {
    dispatch({
      type: 'SET_CURRENT_PAGE',
      payload: {
        currentPage,
      },
    });
  },
  handleChangeSize: (currentSize) => {
    dispatch({
      type: 'SET_CURRENT_BRICK_SIZE',
      payload: {
        currentSize,
      },
    });
  },
  handleChangeBond: (currentBond) => {
    dispatch({
      type: 'SET_CURRENT_BOND',
      payload: {
        currentBond,
      },
    });
  },
  handleChangeSeamType: (currentSeamType) => {
    dispatch({
      type: 'SET_CURRENT_SEAM_TYPE',
      payload: {
        currentSeamType,
      },
    });
  },
  handleChangeSeamColor: (currentSeamColor) => {
    dispatch({
      type: 'SET_CURRENT_SEAM_COLOR',
      payload: {
        currentSeamColor,
      },
    });
  },
  handleChangeBrickValues: (brickValues) => {
    dispatch({
      type: 'SET_BRICK_VALUES',
      payload: {
        brickValues,
      },
    });
  },
  handleChangeBrickRanges: (brickRanges) => {
    dispatch({
      type: 'SET_BRICK_RANGES',
      payload: {
        brickRanges,
      },
    });
  },
  handleCommitBrickRanges: (brickRanges) => {
    dispatch({
      type: 'COMMIT_BRICK_RANGES',
      payload: {
        brickRanges,
      },
    });
  },
  handleChangeIsMobileTutorial: (isMobileTutorial) => {
    dispatch({
      type: 'SET_IS_MOBILE_TUTORIAL',
      payload: {
        isMobileTutorial,
      }
    })
  },
  handleChangeIsViewerVisible: (isViewerVisible) => {
    dispatch({
      type: 'SET_IS_VIEWER_VISIBLE',
      payload: {
        isViewerVisible,
      },
    })
  },
  handleAddBrick: () => {
    dispatch({ type: 'ADD_BRICK' });
  },
});

export default actions;