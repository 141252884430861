import React from 'react';

export const MinSeamSize = () => (
  <svg width="71" height="26" viewBox="0 0 71 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 18H17V26H0V18Z" fill="black" />
    <path d="M18 18H35V26H18V18Z" fill="black" />
    <path d="M9 9H26V17H9V9Z" fill="black" />
    <path d="M36 18H53V26H36V18Z" fill="black" />
    <path d="M54 18H71V26H54V18Z" fill="black" />
    <path d="M45 9H62V17H45V9Z" fill="black" />
    <path d="M53 8L36 8V0L53 1.48619e-06V8Z" fill="black" />
    <path d="M35 8L18 8V0L35 1.48619e-06V8Z" fill="black" />
    <path d="M44 17L27 17V9L44 9V17Z" fill="black" />
  </svg>
);
