import React, { useRef, useContext, memo } from 'react';
import { AppContext } from 'App';
import Header from './Header';
import { PageContainer, FirstPageTableContainer, ValueItem, ValueLabel, ValueData, SecondPageHeading, SecondPageTable, PDFContainer } from './styled';
import { defineBricksPercentage } from 'brickSizes';
import { seamTypesOptions } from 'components/SeamSelector/options';
import { bondOptions } from 'components/BondSelector/options';
import Table from './Table';
import { createPreviewTableData, createMarksTableData } from './Table/createTableData';

const FirstPagePDF = () => {
  const {
    brickValues,
    brickRanges,

    currentSeamType: seamSize,
    currentBond: bondType,
    currentSize: brickSize,

  } = useContext(AppContext);

  const valuesForBricks = defineBricksPercentage(brickValues, brickRanges, brickSize);

  const seamTypeLabel = seamTypesOptions.find(({ value }) => value === seamSize).label;
  const bondTypeLabel = bondOptions.find(({ value }) => value === bondType).firstLabel;

  const tableData = createPreviewTableData(valuesForBricks, brickSize);

  return (
    <PageContainer>
      <Header />

      <canvas id='first-page-canvas' height='616' width='801' />

      <FirstPageTableContainer>
        <Table isWithImages data={tableData} />
      </FirstPageTableContainer>

      <ValueItem>
        <ValueLabel>
          Тип шва
        </ValueLabel>

        <ValueData>
          {seamTypeLabel}
        </ValueData>
      </ValueItem>

      <ValueItem>
        <ValueLabel>
          Перевязка
        </ValueLabel>

        <ValueData>
          {bondTypeLabel}
        </ValueData>
      </ValueItem>
    </PageContainer>
  );
};

const SecondPagePdf = () => {
  const {
    brickValues,
    brickRanges,

    currentSize: brickSize,

  } = useContext(AppContext);

  const canvasRef = useRef(null);

  const tableData = createMarksTableData(brickValues, brickSize, brickRanges);

  return (
    <PageContainer>
      <Header />

      <SecondPageHeading>Матрица раскладки по цвету</SecondPageHeading>

      <canvas id='second-page-canvas' height='616' width='801' ref={canvasRef} />

      <SecondPageTable>
        <Table data={tableData} />
      </SecondPageTable>
    </PageContainer>
  );
};

export const PDF = memo(() => (
  <PDFContainer id='pdf-container'>
    <FirstPagePDF />
    <SecondPagePdf />
  </PDFContainer>
));
