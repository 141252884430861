import React from 'react';

export const BondLogo = () => (
  <svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="8" width="8" height="8" fill="#FFFFFF" />
    <rect x="18" width="8" height="7" fill="#FFFFFF" />
    <rect x="14" y="8" width="17" height="8" fill="#FFFFFF" />
    <rect width="17" height="7" fill="#FFFFFF" />
  </svg>
);
