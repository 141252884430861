import React, { memo } from 'react';
import Modal from 'react-modal';
import closeIcon from './close-icon.svg';
import { ModalText, ModalButtons, ModalButton, CloseModalIcon } from './styled';
import equals from 'ramda/es/equals';

export const DownloadModal = memo(({ isModalOpen, handleCloseModal, handleDownloadPDF, handleDownloadImage }) => {
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 250,
      height: 123,
      display: 'flex',
      padding: 0,
      flexDirection: 'column',
      overflow: 'visible',
    },
    overlay: {
      zIndex: 99,
      background: 'rgba(0, 0, 0, 0.7)',
    },
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      style={customStyles}
    >
      <CloseModalIcon src={closeIcon} onClick={handleCloseModal} />
      <ModalText>Скачать результат</ModalText>

      <ModalButtons>
        <ModalButton onClick={handleDownloadPDF}>PDF</ModalButton>

        <ModalButton onClick={handleDownloadImage}>JPG</ModalButton>
      </ModalButtons>
    </Modal>
  )
}, equals);