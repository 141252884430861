import styled from 'styled-components';

export const ModalText = styled.span`
  font-family: Playfair Display;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  margin-top: 1rem;
  width: 100%;

  text-align: center;

  color: #000000;
`;

export const ModalButtons = styled.div`
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 46px;

  display: flex;
  justify-content: space-between;
`;

export const CloseModalIcon = styled.img`
  position: absolute;
  top: -19%;
  left: 99%;
  cursor: pointer;
`;

export const ModalButton = styled.button`
  width: 80px;
  height: 24px;

  background: #C8AF64;
  border-radius: 3px;

  outline: none;
  border: 0;

  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1rem;

  color: #FFFFFF;
  cursor: pointer;
`;
