import React, { useMemo, useContext, useCallback, useState, memo } from 'react';
import {
  NavigationContainer, Logo, NavigationButtons, NavigationItem,
  NavigationButton, ButtonLogo, ButtonText, DownloadBlock, DownloadButton,
  DownloadButtonText, DownloadLogoBlock, LogoBlock,
} from './styled';
import fjLogo from './fj-logo.svg';
import { AppContext } from 'App';
import { seamColorOptions } from 'components/SeamSelector/options';

import { DownloadLogo } from './logo/DownloadLogo';
import { navigationButtons } from './steps';
import { createImage } from 'createImage';
import { createBrickLayout } from 'createBrickLayout';
import { defineBricksPercentage } from 'brickSizes';
import { createPDF } from 'createPDF';
import { DownloadModal } from './DownloadModal/DownloadModal';
import Loader from './Loader';
import equals from 'ramda/es/equals';

export const Navigation = memo(() => {
  const {
    setCurrentPage,
    currentPage,
    brickValues,
    brickRanges,
    currentSeamType: seamSize,
    currentSeamColor: seamColor,
    currentBond: bondType,
    currentSize: brickSize,
  } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [loaderText, setLoaderText] = useState('');
  const isTutorial = useMemo(() => brickValues.every(({ type }) => !type), [brickValues]);

  const seamImage = seamColorOptions.find(({ colorCode }) => colorCode === seamColor).imagePath;

  const valuesForBricks = defineBricksPercentage(brickValues, brickRanges, brickSize);

  const handleNavigate = useCallback((e) => {
    const { dataset: { navigationValue } } = e.currentTarget;

    setCurrentPage(Number(navigationValue));
  }, [setCurrentPage]);

  const handleDownloadPDF = () => {
    setIsModalOpen(false);

    if (!isTutorial) {
      (async () => {
        setLoaderText('Генерация PDF');
        setIsLoaderVisible(true);

        await createPDF(valuesForBricks, brickSize, seamSize, seamImage, bondType);

        setIsLoaderVisible(false);
      })();
    }
  };

  const handleDownloadImage = () => {
    setIsModalOpen(false);

    if (!isTutorial) {
      createImage(async (canvas) => {
        setLoaderText('Генерация изображения');
        setIsLoaderVisible(true);

        await createBrickLayout(canvas, valuesForBricks, brickSize, seamSize, seamImage, bondType);

        setIsLoaderVisible(false);
      });
    }
  };

  const handleDownload = useCallback(() => {
    if (!isTutorial) {
      setIsModalOpen(true);
    }
  }, [isTutorial]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const renderButtons = useMemo(
    () => navigationButtons.map((button, index) => (
      <NavigationItem key={button.label}>
        <NavigationButton
          data-navigation-value={button.value}
          active={currentPage === button.value}
          fillIcon={index !== 2}
          onClick={handleNavigate}
        >
          <ButtonLogo>
            {button.logo}
          </ButtonLogo>

          <ButtonText>
            {button.label}
          </ButtonText>
        </NavigationButton>
      </NavigationItem>
    )),
    [currentPage, handleNavigate],
  );

  return (
    <NavigationContainer>
      <DownloadModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        handleDownloadPDF={handleDownloadPDF}
        handleDownloadImage={handleDownloadImage}
      />

      <Loader isVisible={isLoaderVisible} loaderText={loaderText} />

      <LogoBlock>
        <Logo src={fjLogo} />
      </LogoBlock>

      <NavigationButtons>
        {renderButtons}
      </NavigationButtons>

      <DownloadBlock>
        <DownloadButton onClick={handleDownload}>
          <DownloadLogoBlock>
            <DownloadLogo />
          </DownloadLogoBlock>

          <DownloadButtonText>Скачать</DownloadButtonText>
        </DownloadButton>
      </DownloadBlock>
    </NavigationContainer>
  );
}, equals);
