import React from 'react';

export const BoundedBond = () => (
  <svg width="58" height="26" viewBox="0 0 58 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 9H21V17H11V9Z" fill="black" className='main' />
    <path d="M0 9H10V17H0V9Z" fill="black" className='main' />
    <path d="M33 9H43V17H33V9Z" fill="black"  className='marked'/>
    <path d="M44 9H54V17H44V9Z" fill="black" className='main' />
    <path d="M36 8L26 8V0L36 1.45705e-06V8Z" fill="black" className='marked' />
    <path d="M36 26L26 26V18L36 18V26Z" fill="black" className='marked' />
    <path d="M47 8L37 8V0L47 1.45705e-06V8Z" fill="black" className='main' />
    <path d="M58 8L48 8V0L58 1.45705e-06V8Z" fill="black" className='main' />
    <path d="M47 26L37 26V18L47 18V26Z" fill="black" className='main' />
    <path d="M58 26L48 26V18L58 18V26Z" fill="black" className='main' />
    <path d="M25 8L15 8V0L25 1.45705e-06V8Z" fill="black" className='main' />
    <path d="M14 8L4 8L4 0L14 1.45705e-06V8Z" fill="black" className='main' />
    <path d="M25 26L15 26V18L25 18V26Z" fill="black" className='main' />
    <path d="M14 26L4 26L4 18L14 18V26Z" fill="black" className='main' />
    <path d="M32 17L22 17V9L32 9V17Z" fill="black"  className='main'/>
  </svg>
);
