import styled, { css } from 'styled-components';
import { ifProp, ifNotProp } from 'styled-tools';

export const ChooseBrickIconContainer = styled.div`
  width: 4rem;
  height: 4rem;

  background: #C8AF64;
  border-radius: .25rem;

  margin-bottom: 1rem;
  
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BrickSelectorContainer = styled.div``;

export const SelectorMainViewContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  overflow: hidden;
`;

export const AddButtonBlock = styled.div`
  height: 2rem;

  margin: 3px 0 13px .9375rem;

  display: flex;
  cursor: pointer;

  ${ChooseBrickIconContainer} {
    height: 2rem;
    width: 2rem;
    
    margin-left: 3px;

    svg {
      height: 20px;
    }
  }
`;

export const AddButtonBlockText = styled.div`
  background: #ECECEC;
  display: flex;

  justify-content: center;
  align-items: center;

  width: 14.4875rem;

  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;

  color: #000000;

  @media screen and (max-width: 1000px) {
    width: calc(100% - 71px);
  }
`;

export const SelectedSections = styled.div`
  margin-left: 0.9375rem;
  width: 16.375rem;
  flex-basis: 100%;
  display: flex;
  box-sizing: border-box;

  flex-direction: column;

  margin-top: .75rem;
  margin-bottom: .75rem;
  overflow: hidden;

  z-index: 1;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const SelectorsMainView = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const TransparentBlock = styled.div`
  width: 100%;
  min-height: 2px;
  bottom: 0px;

  background: #2E453E;
`;

export const SelectedSection = styled.div`
  display: flex;
  flex-direction: column;
  background: #ECECEC;

  min-height: ${({ height }) => height}%;
  max-height: ${({ height }) => height}%;

  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid #C8AF64;
  }

  box-sizing: border-box;
`;

export const SelectedSectionContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-basis: calc(100% - 2px);
  flex-grow: 1;
  flex-shrink: 0;
`;

export const AfterSelectedSection = styled.div`
  height: 1px;
  width: 100%;
  background: #C8AF64;

  margin-bottom: .15625rem;
`;

export const ScaleBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 2.3125rem;
  margin-bottom: .75rem;
  margin-top: .75rem;
`;

export const ScaleMarkContainer = styled.div`
  height: ${({ scaleblockheight }) => scaleblockheight}%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const ScaleText = styled.p`
  margin: 0;
  margin-right: .1875rem;
  margin-bottom: .3rem;

  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: .6875rem;
  line-height: .8125rem;
  text-align: end;

  color: #FFFFFF;
`;

export const ScaleMark = styled.div`
  height: 1px;
  width: 1.3125rem;

  background: #C8AF64;

  ${ifProp({ isbold: true }, css`
    height: 2px;
  `)}
`;

export const DragTrack = styled.div`
  flex-grow: 1;
  width: 2.25rem;
  display: flex;

  top: calc(10% + .55rem);
  height: calc(80% - 1.1rem);

  ${({ isWithButton }) => isWithButton && css`top: calc(10% + .25rem);`}
  ${({ isWithButton }) => isWithButton && css`height: calc(80% - 1.1rem - 40px);`}

  z-index: 3;
  position: absolute;
  left: 17.5625rem;

  @media screen and (max-width: 1000px) {
    left: calc(100% - 52px);
    top: calc(10% + 53px);
    height: calc(80% - 1.1rem - 56px - 33px);

    ${({ isWithButton }) => isWithButton && css`top: calc(10% + 48px);`}
    ${({ isWithButton }) => isWithButton && css`height: calc(80% - 1.1rem - 127px);`}

    justify-content: center;
  }
`;

export const DragTrackInnerBlock = styled.div`
  width: 5px;
  height: 100%;
  border-radius: 4px;
  align-self: center;
`;

export const DragThumbContainer = styled.div`
  height: 1.6875rem;
  width: 1.6875rem;
  border-radius: 5px;

  background-color: #2E453E;

  display: flex;
  outline: none;
  justify-content: center;
  align-items: center;
`;

export const DragThumb = styled.div`
  width: 1.4375rem;
  height: 1.4375rem;
  border-radius: 3px;
  background: #C8AF64;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: .875rem;
    height: .4375rem;
  }
`;

export const ChooseBrickBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
`;

export const ChooseBrickText = styled.p`
  margin: 0;
  font-family: PT Serif;
`;


export const PercentOfBrick = styled.p`
  margin: 0;
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: .6875rem;
  line-height: .8125rem;

  margin-right: .35rem;
  text-align: center;
`;

export const ChooseItemContainer = styled.div`
  margin-top: .75rem;
  margin-left: .9375rem;
  display: flex;

  height: calc(100% - .75rem);
  width: calc(100% - .625rem);
`;

export const ChooseItemOptions = styled.ul`
  padding: 0;

  width: 17.9375rem;
  background: #ECECEC;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    width: calc(100% - .75rem); 
  }
`;

export const ChooseItemOptionButton = styled.button`
  //width: 5rem;
  width: 68px;
  height: 1.5rem;
  border: none;
  outline: none;

  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;

  color: #FFFFFF;

  background: #C8AF64;
  border-radius: 0.1875rem;

  position: absolute;
  cursor: pointer;
  margin-top: .2rem;
  //margin-left: 2.3rem;
  //margin-left: 11px;
  margin-left: 16px;
`;

export const ChooseItemOption = styled.li`
  list-style-type: none;

  //height: 5.0625rem;
  height: 6.5rem;
  width: 100%;

  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  border-bottom: 1px solid #3D3D44;   

  ${ifNotProp({ isActive: true }, css`
    &:hover {
      background: #D4EAE2;
    }
  `)}
`;

export const ChooseItemOptionImage = styled.img`
  border-radius: .25rem;
  border-color: transparent;

  //width: 134px;
  //min-width: 134px;
  //height: 3rem;
  width: 77px;
  min-width: 77px;
  height: 4.7rem;

  margin: 0 .875rem 0 .6875rem;

  box-sizing: border-box;

  ${ifProp({ isActive: true }, css`
    border: 3px solid #C8AF64;
  `)}
`;

export const SelectedOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
`;

export const KermaPremiumImage = styled.img`
  position: absolute;
  margin-left: 4.8125rem;
`;

export const SelectedOptionImage = styled.div`
  border-radius: .25rem;
  border-color: transparent;

  //width: 8.375rem;
  //height: 3rem;
  width: 77px;
  height: 4.7rem;
  
  flex-grow: 0;
  flex-shrink: 0;

  margin: 0 .875rem 0 .6875rem;

  box-sizing: border-box;
  
  background-image: url(${({ background }) => background});
  background-size: contain;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectedOptionIconBlock = styled.div`
  background: #C8AF64;
  border-radius: 3px;
  width: 1.5rem;
  height: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:first-of-type {
    margin-right: .6875rem;
  }
`;

// max-width: 4.6875rem;
export const ChooseItemOptionText = styled.p`
  font-family: PT Serif;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  //max-width: 44%;
  max-width: 60%;
  line-height: 1rem;

  color: #000000;

  ${ifProp({ isActive: true }, css`
    color: #C8AF64;
  `)}
`;

export const SelectedOptionLabel = styled(ChooseItemOptionText)`
  word-break: break-word;
`;
